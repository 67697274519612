/* ========= [ * page-home_3 ]  =========*/
/* --------------- side_menu_style4 --------------- */
.side_menu_style4 {
  position: fixed;
  z-index: 9999;
  right: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  min-height: -webkit-max-content;
  min-height: -moz-max-content;
  min-height: max-content;
  background-color: #DFE3EC;
  padding: 0 4vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s; }
  .side_menu_style4.show {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); }
    .side_menu_style4.show .side_shape {
      -webkit-transform: rotate(0) scale(1);
      -ms-transform: rotate(0) scale(1);
      transform: rotate(0) scale(1);
      opacity: 1; }
    .side_menu_style4.show .side_shape2 {
      -webkit-transform: rotate(180deg) scale(1);
      -ms-transform: rotate(180deg) scale(1);
      transform: rotate(180deg) scale(1);
      opacity: 1; }
    .side_menu_style4.show .main_links {
      text-align: center;
      -webkit-transform: scaleX(1) scaleY(1);
      -ms-transform: scaleX(1) scaleY(1);
      transform: scaleX(1) scaleY(1);
      opacity: 1;
      -webkit-filter: blur(0);
      filter: blur(0); }
  .side_menu_style4 .side_shape {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40%;
    -webkit-transform: rotate(-60deg) scale(0.5);
    -ms-transform: rotate(-60deg) scale(0.5);
    transform: rotate(-60deg) scale(0.5);
    opacity: 0;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-transition-delay: 0.7s;
    -o-transition-delay: 0.7s;
    transition-delay: 0.7s; }
  .side_menu_style4 .side_shape2 {
    position: absolute;
    left: 0;
    top: 0;
    width: 40%;
    -webkit-transform: rotate(120deg) scale(0.5);
    -ms-transform: rotate(120deg) scale(0.5);
    transform: rotate(120deg) scale(0.5);
    opacity: 0;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-transition-delay: 0.7s;
    -o-transition-delay: 0.7s;
    transition-delay: 0.7s; }
  .side_menu_style4 .links_group {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    display: block; }
  .side_menu_style4 .main_links {
    text-align: center;
    -webkit-transform: scaleX(1.4) scaleY(1.8);
    -ms-transform: scaleX(1.4) scaleY(1.8);
    transform: scaleX(1.4) scaleY(1.8);
    -webkit-filter: blur(2px);
    filter: blur(2px);
    opacity: 0;
    -webkit-transition: all 0.7s ease;
    -o-transition: all 0.7s ease;
    transition: all 0.7s ease;
    -webkit-transition-delay: 1s;
    -o-transition-delay: 1s;
    transition-delay: 1s; }
    .side_menu_style4 .main_links .main_link {
      font-size: calc(3vw + 15px);
      font-weight: 400;
      margin: 10px 0;
      text-transform: uppercase;
      font-weight: 700; }
      .side_menu_style4 .main_links .main_link:hover {
        text-decoration: underline; }
  .side_menu_style4 .social_links a {
    font-size: 24px;
    -webkit-margin-end: 15px;
    margin-inline-end: 15px;
    border-bottom: 3px solid transparent; }
    .side_menu_style4 .social_links a:hover {
      border-color: #000; }
  .side_menu_style4 .clss {
    font-size: 30px;
    position: absolute;
    top: 30px;
    right: 60px;
    cursor: pointer; }

.side_menu4_overlay {
  position: absolute;
  z-index: 90;
  right: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--color-orange1);
  -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out; }
  .side_menu4_overlay.show {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); }

.side_menu4_overlay2 {
  position: absolute;
  z-index: 80;
  right: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--color-orange1);
  -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transition-delay: 0.7s;
  -o-transition-delay: 0.7s;
  transition-delay: 0.7s; }
  .side_menu4_overlay2.show {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    -webkit-transition-delay: 0.1s;
    -o-transition-delay: 0.1s;
    transition-delay: 0.1s; }

/* --------------- inner-navbar-style1 --------------- */
.inner-navbar-style1 {
  position: relative;
  padding: 50px 4vw; }
  .inner-navbar-style1 .logo {
    width: 100px; }
  .inner-navbar-style1 .navbar-nav .nav-link {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    -webkit-margin-start: 30px;
    margin-inline-start: 30px;
    text-transform: capitalize; }
  .inner-navbar-style1 .nav-side {
    position: relative; }
    .inner-navbar-style1 .nav-side a {
      font-size: 14px;
      font-weight: 600;
      color: #000; }
  .inner-navbar-style1 .nav-search-box {
    position: absolute;
    background-color: #fff;
    -webkit-box-shadow: 5px 5px 20px #fff1;
    box-shadow: 5px 5px 20px #fff1;
    right: 0;
    top: calc(100% + 15px);
    width: 100%;
    min-width: 250px;
    padding: 15px;
    border-radius: 10px;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px);
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-box-shadow: 0 0 20px #0001;
    box-shadow: 0 0 20px #0001; }
    .inner-navbar-style1 .nav-search-box.show {
      visibility: visible;
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0); }
    .inner-navbar-style1 .nav-search-box .form-group {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .inner-navbar-style1 .nav-search-box .form-group input {
        width: 100%;
        background-color: transparent;
        border: 1px solid #9993;
        border-right: 0;
        border-radius: 8px 0 0 8px;
        min-height: 50px;
        color: #000;
        padding: 12px 15px; }
      .inner-navbar-style1 .nav-search-box .form-group button {
        -ms-flex-negative: 0;
        flex-shrink: 0;
        border-radius: 0 8px 8px 0;
        border: 1px solid #9993;
        background-color: #9993;
        padding: 10px;
        color: #000;
        margin-left: -2px;
        -webkit-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease; }
        .inner-navbar-style1 .nav-search-box .form-group button:hover {
          background-color: var(--color-orange1);
          color: #fff; }

@media screen and (max-width: 991px) {
  .inner-navbar-style1 .navbar-nav {
    margin-top: 30px; }
    .inner-navbar-style1 .navbar-nav .nav-link {
      -webkit-margin-start: 0;
      margin-inline-start: 0; }
  .inner-navbar-style1 .ms-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important; } }

/* --------------- portfolio pg style1 --------------- */
.portfolio-pg-style1 {
  position: relative; }
  .portfolio-pg-style1 .tc-featured-cases-style1 {
    padding: 120px 0; }
    .portfolio-pg-style1 .tc-featured-cases-style1 .tab-content {
      position: relative; }
      .portfolio-pg-style1 .tc-featured-cases-style1 .tab-content * {
        cursor: none; }
      .portfolio-pg-style1 .tc-featured-cases-style1 .tab-content .float-cursor {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) scale(0);
        -ms-transform: translate(-50%, -50%) scale(0);
        transform: translate(-50%, -50%) scale(0);
        opacity: 0;
        width: 160px;
        height: 160px;
        background-color: #fff;
        border-radius: 50%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        font-size: 16px;
        z-index: 10;
        -webkit-transition: all 0.02 linear;
        -o-transition: all 0.02 linear;
        transition: all 0.02 linear;
        pointer-events: none;
        -webkit-box-shadow: 0 0 30px #0001;
        box-shadow: 0 0 30px #0001; }
        .portfolio-pg-style1 .tc-featured-cases-style1 .tab-content .float-cursor.show {
          opacity: 1;
          -webkit-transform: translate(-50%, -50%) scale(1);
          -ms-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1); }
    @media screen and (min-width: 1200px) {
      .portfolio-pg-style1 .tc-featured-cases-style1 .content {
        -webkit-margin-start: calc((100vw - 1320px) / 2);
        margin-inline-start: calc((100vw - 1320px) / 2); } }
    .portfolio-pg-style1 .tc-featured-cases-style1 .content .nav-pills .nav-link {
      font-size: 18px;
      line-height: 24px;
      color: #666;
      background-color: transparent;
      padding: 0;
      -webkit-margin-end: 50px;
      margin-inline-end: 50px;
      text-transform: capitalize; }
      .portfolio-pg-style1 .tc-featured-cases-style1 .content .nav-pills .nav-link.active {
        color: var(--color-orange1); }
    .portfolio-pg-style1 .tc-featured-cases-style1 .content .cases-slider {
      position: relative;
      overflow: hidden; }
    .portfolio-pg-style1 .tc-featured-cases-style1 .content .case-card .img {
      display: block;
      height: 600px;
      border-radius: 15px;
      overflow: hidden; }
    .portfolio-pg-style1 .tc-featured-cases-style1 .content .case-card .info {
      padding-top: 30px; }
      .portfolio-pg-style1 .tc-featured-cases-style1 .content .case-card .info .tags a {
        font-size: 12px;
        font-weight: 500;
        padding: 7px 25px;
        border: 1px solid #ccc;
        border-radius: 30px;
        -webkit-margin-end: 5px;
        margin-inline-end: 5px; }
  .portfolio-pg-style1 .tc-latest-cases-style1 .filter .links a {
    font-size: 18px;
    line-height: 24px;
    color: #666;
    background-color: transparent;
    padding: 0;
    -webkit-margin-end: 50px;
    margin-inline-end: 50px;
    text-transform: capitalize; }
    .portfolio-pg-style1 .tc-latest-cases-style1 .filter .links a.active {
      color: var(--color-orange1); }
  .portfolio-pg-style1 .tc-latest-cases-style1 .content {
    padding: 150px 0;
    border-top: 1px solid #ccc; }
    .portfolio-pg-style1 .tc-latest-cases-style1 .content .case-card {
      position: relative;
      margin-bottom: 100px; }
      .portfolio-pg-style1 .tc-latest-cases-style1 .content .case-card .img {
        height: 400px;
        border-radius: 15px;
        overflow: hidden;
        display: block; }
      .portfolio-pg-style1 .tc-latest-cases-style1 .content .case-card .info {
        padding-top: 30px; }
        .portfolio-pg-style1 .tc-latest-cases-style1 .content .case-card .info .tags a {
          font-size: 12px;
          font-weight: 500;
          padding: 7px 25px;
          border: 1px solid #ccc;
          border-radius: 30px;
          -webkit-margin-end: 5px;
          margin-inline-end: 5px; }

@media screen and (max-width: 991px) {
  .portfolio-pg-style1 .tc-featured-cases-style1 {
    padding: 80px 0; }
    .portfolio-pg-style1 .tc-featured-cases-style1 .content {
      padding: 0 15px; }
      .portfolio-pg-style1 .tc-featured-cases-style1 .content .nav-pills .nav-link {
        margin-bottom: 10px;
        font-size: 13px;
        -webkit-margin-end: 15px;
        margin-inline-end: 15px;
        font-weight: bold; }
      .portfolio-pg-style1 .tc-featured-cases-style1 .content .case-card .img {
        height: 300px; }
  .portfolio-pg-style1 .tc-latest-cases-style1 .filter .links a {
    margin-bottom: 10px;
    font-size: 13px;
    -webkit-margin-end: 15px;
    margin-inline-end: 15px;
    font-weight: bold; }
  .portfolio-pg-style1 .tc-latest-cases-style1 .content {
    padding: 80px 0; }
    .portfolio-pg-style1 .tc-latest-cases-style1 .content .case-card {
      margin-bottom: 50px; }
      .portfolio-pg-style1 .tc-latest-cases-style1 .content .case-card .img {
        height: 250px; } }

/* --------------- footer style1 --------------- */
.s-project-pg-style1 .tc-project-style1 .tags a {
  font-size: 12px;
  font-weight: 500;
  padding: 7px 25px;
  border: 1px solid #ccc;
  border-radius: 30px;
  -webkit-margin-end: 5px;
  margin-inline-end: 5px; }

.s-project-pg-style1 .tc-project-style1 .main-info {
  padding: 150px 0; }
  .s-project-pg-style1 .tc-project-style1 .main-info .title {
    font-size: 150px;
    font-weight: 500;
    line-height: 150px;
    letter-spacing: -0.05em; }

.s-project-pg-style1 .tc-project-style1 .main-img {
  height: 900px;
  border-radius: 30px 30px 0 0;
  overflow: hidden; }
  .s-project-pg-style1 .tc-project-style1 .main-img img {
    max-height: unset;
    height: 200%;
    -o-object-position: top;
    object-position: top;
    margin-top: -25%; }

.s-project-pg-style1 .tc-project-style1 .description {
  position: relative;
  padding: 150px 0;
  border-radius: 30px 30px 0 0;
  margin-top: -30px;
  z-index: 10;
  background-color: #fff; }

.s-project-pg-style1 .tc-project-style1 .services {
  position: relative; }
  .s-project-pg-style1 .tc-project-style1 .services .line {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 45%;
    -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    -webkit-transition: all 3s linear;
    -o-transition: all 3s linear;
    transition: all 3s linear; }
    .s-project-pg-style1 .tc-project-style1 .services .line.animated {
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); }

.s-project-pg-style1 .tc-project-style1 .main-slider-img {
  position: relative;
  margin-top: -30px;
  border-radius: 30px 30px 0 0;
  z-index: 10;
  overflow: hidden; }
  .s-project-pg-style1 .tc-project-style1 .main-slider-img .img {
    height: 900px; }
  .s-project-pg-style1 .tc-project-style1 .main-slider-img .arrows {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 90px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #000;
    padding: 5px 15px;
    border-radius: 30px;
    z-index: 20;
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content; }
    .s-project-pg-style1 .tc-project-style1 .main-slider-img .arrows .swiper-button-prev,
    .s-project-pg-style1 .tc-project-style1 .main-slider-img .arrows .swiper-button-next {
      position: static;
      margin: 0;
      height: -webkit-max-content;
      height: -moz-max-content;
      height: max-content; }
      .s-project-pg-style1 .tc-project-style1 .main-slider-img .arrows .swiper-button-prev::after,
      .s-project-pg-style1 .tc-project-style1 .main-slider-img .arrows .swiper-button-next::after {
        font-size: 10px;
        color: #fff; }
    .s-project-pg-style1 .tc-project-style1 .main-slider-img .arrows .swiper-pagination {
      position: static;
      margin-bottom: 4px; }
      .s-project-pg-style1 .tc-project-style1 .main-slider-img .arrows .swiper-pagination .swiper-pagination-bullet {
        width: 5px;
        height: 5px;
        background-color: #fff;
        margin: 0 10px; }
        .s-project-pg-style1 .tc-project-style1 .main-slider-img .arrows .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
          background-color: var(--color-orange1); }

.s-project-pg-style1 .tc-testimonials-style1 {
  position: relative;
  padding: 150px 0; }
  .s-project-pg-style1 .tc-testimonials-style1 .c-line {
    position: absolute;
    left: 0;
    top: 70%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 25%;
    z-index: 5;
    pointer-events: none;
    -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    -webkit-transition: all 2s linear;
    -o-transition: all 2s linear;
    transition: all 2s linear; }
    .s-project-pg-style1 .tc-testimonials-style1 .c-line.animated {
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); }
  .s-project-pg-style1 .tc-testimonials-style1 .lg-icon {
    font-size: 170px;
    -webkit-margin-start: -15px;
    margin-inline-start: -15px;
    display: block; }
  .s-project-pg-style1 .tc-testimonials-style1 .tc-clients-style1 {
    position: relative;
    overflow: hidden; }
    .s-project-pg-style1 .tc-testimonials-style1 .tc-clients-style1 .clients-slider1 {
      position: relative;
      overflow: hidden; }
    .s-project-pg-style1 .tc-testimonials-style1 .tc-clients-style1 .author {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding-top: 50px;
      margin-top: 80px;
      border-top: 1px solid #ccc; }
      .s-project-pg-style1 .tc-testimonials-style1 .tc-clients-style1 .author .au-img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        -webkit-margin-end: 20px;
        margin-inline-end: 20px; }
    .s-project-pg-style1 .tc-testimonials-style1 .tc-clients-style1 .slider-controls {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 10px 20px;
      border-radius: 30px;
      border: 1px solid #ccc;
      position: absolute;
      right: 0;
      bottom: 0;
      background: #fff;
      z-index: 10; }
      .s-project-pg-style1 .tc-testimonials-style1 .tc-clients-style1 .slider-controls .swiper-button-next,
      .s-project-pg-style1 .tc-testimonials-style1 .tc-clients-style1 .slider-controls .swiper-button-prev {
        position: static;
        height: -webkit-max-content;
        height: -moz-max-content;
        height: max-content;
        margin: 0; }
        .s-project-pg-style1 .tc-testimonials-style1 .tc-clients-style1 .slider-controls .swiper-button-next::after,
        .s-project-pg-style1 .tc-testimonials-style1 .tc-clients-style1 .slider-controls .swiper-button-prev::after {
          color: #000;
          font-size: 12px; }
        .s-project-pg-style1 .tc-testimonials-style1 .tc-clients-style1 .slider-controls .swiper-button-next:hover::after,
        .s-project-pg-style1 .tc-testimonials-style1 .tc-clients-style1 .slider-controls .swiper-button-prev:hover::after {
          color: var(--color-orange1); }
      .s-project-pg-style1 .tc-testimonials-style1 .tc-clients-style1 .slider-controls .swiper-pagination {
        position: static;
        margin: 0 10px; }
        .s-project-pg-style1 .tc-testimonials-style1 .tc-clients-style1 .slider-controls .swiper-pagination .swiper-pagination-bullet {
          width: 8px;
          height: 8px;
          margin: 0 5px; }
          .s-project-pg-style1 .tc-testimonials-style1 .tc-clients-style1 .slider-controls .swiper-pagination .swiper-pagination-bullet .swiper-pagination-bullet-active {
            width: 10px;
            height: 10px; }

.s-project-pg-style1 .tc-related-projects-style1 {
  padding: 120px 0; }
  .s-project-pg-style1 .tc-related-projects-style1 .tab-content {
    position: relative; }
    .s-project-pg-style1 .tc-related-projects-style1 .tab-content * {
      cursor: none; }
    .s-project-pg-style1 .tc-related-projects-style1 .tab-content .float-cursor {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%) scale(0);
      -ms-transform: translate(-50%, -50%) scale(0);
      transform: translate(-50%, -50%) scale(0);
      opacity: 0;
      width: 160px;
      height: 160px;
      background-color: #fff;
      border-radius: 50%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      font-size: 16px;
      z-index: 10;
      -webkit-transition: all 0.02 linear;
      -o-transition: all 0.02 linear;
      transition: all 0.02 linear;
      pointer-events: none;
      -webkit-box-shadow: 0 0 30px #0001;
      box-shadow: 0 0 30px #0001; }
      .s-project-pg-style1 .tc-related-projects-style1 .tab-content .float-cursor.show {
        opacity: 1;
        -webkit-transform: translate(-50%, -50%) scale(1);
        -ms-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1); }
  @media screen and (min-width: 1200px) {
    .s-project-pg-style1 .tc-related-projects-style1 .content {
      -webkit-margin-start: calc((100vw - 1320px) / 2);
      margin-inline-start: calc((100vw - 1320px) / 2); } }
  .s-project-pg-style1 .tc-related-projects-style1 .content .nav-pills .nav-link {
    font-size: 18px;
    line-height: 24px;
    color: #666;
    background-color: transparent;
    padding: 0;
    -webkit-margin-end: 50px;
    margin-inline-end: 50px;
    text-transform: capitalize; }
    .s-project-pg-style1 .tc-related-projects-style1 .content .nav-pills .nav-link.active {
      color: var(--color-orange1); }
  .s-project-pg-style1 .tc-related-projects-style1 .content .cases-slider {
    position: relative;
    overflow: hidden; }
  .s-project-pg-style1 .tc-related-projects-style1 .content .case-card .img {
    display: block;
    height: 600px;
    border-radius: 15px;
    overflow: hidden; }
  .s-project-pg-style1 .tc-related-projects-style1 .content .case-card .info {
    padding-top: 30px; }
    .s-project-pg-style1 .tc-related-projects-style1 .content .case-card .info .tags a {
      font-size: 12px;
      font-weight: 500;
      padding: 7px 25px;
      border: 1px solid #ccc;
      border-radius: 30px;
      -webkit-margin-end: 5px;
      margin-inline-end: 5px; }

.s-project-pg-style1 .tc-chat-style1 {
  position: relative;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#F1EEE9), color-stop(#f1eee9cc), to(transparent));
  background-image: -webkit-linear-gradient(bottom, #F1EEE9, #f1eee9cc, transparent);
  background-image: -o-linear-gradient(bottom, #F1EEE9, #f1eee9cc, transparent);
  background-image: linear-gradient(to top, #F1EEE9, #f1eee9cc, transparent); }
  .s-project-pg-style1 .tc-chat-style1 .content {
    position: relative;
    padding: 150px 0;
    border-top: 1px solid #ccc; }
  .s-project-pg-style1 .tc-chat-style1 .c-line {
    position: absolute;
    right: 0;
    bottom: 70px;
    width: 60%;
    pointer-events: none;
    height: 450px;
    pointer-events: none;
    -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
    clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
    -webkit-transition: all 3s linear;
    -o-transition: all 3s linear;
    transition: all 3s linear; }
    .s-project-pg-style1 .tc-chat-style1 .c-line.animated {
      -webkit-clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
      clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%); }
  .s-project-pg-style1 .tc-chat-style1 .xl-text {
    font-size: 120px;
    font-weight: 500;
    position: relative;
    z-index: 10;
    line-height: 1;
    color: var(--color-orange1);
    margin-bottom: 20px; }

@media screen and (max-width: 991px) {
  .s-project-pg-style1 .fsz-45 {
    font-size: 25px !important; }
  .s-project-pg-style1 .tc-testimonials-style1 {
    padding: 50px 0; }
    .s-project-pg-style1 .tc-testimonials-style1 .lg-icon {
      font-size: 120px; }
  .s-project-pg-style1 .tc-chat-style1 .xl-text {
    font-size: 35px; }
  .s-project-pg-style1 .tc-project-style1 .main-info {
    padding: 100px 0; }
    .s-project-pg-style1 .tc-project-style1 .main-info .title {
      font-size: 65px;
      line-height: 75px; }
  .s-project-pg-style1 .tc-project-style1 .main-img {
    height: 400px; }
    .s-project-pg-style1 .tc-project-style1 .main-img img {
      -o-object-position: center;
      object-position: center; }
  .s-project-pg-style1 .tc-project-style1 .th-600 {
    height: 400px !important; }
  .s-project-pg-style1 .tc-project-style1 .main-slider-img .img {
    height: 400px; }
  .s-project-pg-style1 .tc-related-projects-style1 .content {
    padding: 15px; }
    .s-project-pg-style1 .tc-related-projects-style1 .content .case-card .img {
      height: 400px; }
  .s-project-pg-style1 .tc-testimonials-style1 .tc-clients-style1 .clients-slider1 {
    padding-bottom: 80px; }
    .s-project-pg-style1 .tc-testimonials-style1 .tc-clients-style1 .clients-slider1 .slider-controls {
      right: auto;
      left: 0; } }

/* --------------- s-project-pg-style2 --------------- */
.s-project-pg-style2 .s-header-style2 {
  position: relative;
  height: 900px;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  padding: 100px 0;
  color: #fff;
  border-radius: 30px 30px 0 0;
  overflow: hidden; }
  .s-project-pg-style2 .s-header-style2::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: -webkit-gradient(linear, left bottom, left top, from(#000000c8), color-stop(#0005), to(transparent));
    background-image: -webkit-linear-gradient(bottom, #000000c8, #0005, transparent);
    background-image: -o-linear-gradient(bottom, #000000c8, #0005, transparent);
    background-image: linear-gradient(to top, #000000c8, #0005, transparent); }
  .s-project-pg-style2 .s-header-style2 .bg {
    position: absolute;
    left: 0;
    top: 0;
    max-height: unset;
    height: 200%;
    -o-object-position: top;
    object-position: top;
    margin-top: -25%;
    -webkit-transition: all linear;
    -o-transition: all linear;
    transition: all linear; }
  .s-project-pg-style2 .s-header-style2 .tags a {
    font-size: 12px;
    font-weight: 500;
    padding: 7px 25px;
    border: 1px solid #ccc;
    border-radius: 30px;
    -webkit-margin-end: 5px;
    margin-inline-end: 5px; }
  .s-project-pg-style2 .s-header-style2 .title {
    font-size: 150px;
    font-weight: 500;
    line-height: 150px;
    letter-spacing: -0.05em;
    margin-top: 30px; }

.s-project-pg-style2 .tc-main-info-style2 {
  position: relative;
  z-index: 10;
  background-color: #F1EEE9;
  border-radius: 30px 30px 0 0;
  margin-top: -30px;
  padding: 150px 0 180px; }
  .s-project-pg-style2 .tc-main-info-style2 .imgs {
    margin-top: 100px; }
    .s-project-pg-style2 .tc-main-info-style2 .imgs .sm-img {
      position: relative;
      width: 330px;
      height: 400px;
      border-radius: 20px;
      overflow: hidden;
      margin-top: 50px; }
      .s-project-pg-style2 .tc-main-info-style2 .imgs .sm-img img {
        position: absolute;
        left: 0;
        top: -100%;
        height: 300%;
        width: 100%;
        max-height: none;
        -webkit-transition: all linear;
        -o-transition: all linear;
        transition: all linear; }
    .s-project-pg-style2 .tc-main-info-style2 .imgs .lg-img {
      position: relative;
      height: 630px;
      width: 100%;
      border-radius: 20px;
      overflow: hidden;
      margin-top: 50px; }
      .s-project-pg-style2 .tc-main-info-style2 .imgs .lg-img img {
        position: absolute;
        left: 0;
        top: -100%;
        height: 300%;
        width: 100%;
        max-height: none;
        -webkit-transition: all linear;
        -o-transition: all linear;
        transition: all linear; }

.s-project-pg-style2 .tc-challenge-style2 {
  position: relative;
  padding: 150px 0;
  z-index: 20;
  background-color: #fff;
  margin-top: -30px;
  border-radius: 30px 30px 0 0; }
  .s-project-pg-style2 .tc-challenge-style2 .content {
    position: relative;
    overflow: hidden; }
    @media screen and (min-width: 1200px) {
      .s-project-pg-style2 .tc-challenge-style2 .content {
        -webkit-margin-start: calc((100vw - 1320px) / 2);
        margin-inline-start: calc((100vw - 1320px) / 2); } }
    .s-project-pg-style2 .tc-challenge-style2 .content * {
      cursor: none; }
    .s-project-pg-style2 .tc-challenge-style2 .content .float-cursor {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%) scale(0);
      -ms-transform: translate(-50%, -50%) scale(0);
      transform: translate(-50%, -50%) scale(0);
      opacity: 0;
      width: 160px;
      height: 160px;
      background-color: #fff;
      border-radius: 50%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      font-size: 16px;
      z-index: 10;
      -webkit-transition: all 0.02 linear;
      -o-transition: all 0.02 linear;
      transition: all 0.02 linear;
      pointer-events: none;
      -webkit-box-shadow: 0 0 30px #0001;
      box-shadow: 0 0 30px #0001; }
      .s-project-pg-style2 .tc-challenge-style2 .content .float-cursor.show {
        opacity: 1;
        -webkit-transform: translate(-50%, -50%) scale(1);
        -ms-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1); }
    .s-project-pg-style2 .tc-challenge-style2 .content .cases-slider {
      position: relative;
      overflow: hidden; }
    .s-project-pg-style2 .tc-challenge-style2 .content .case-card .img {
      display: block;
      height: 600px;
      border-radius: 15px;
      overflow: hidden; }

.s-project-pg-style2 .tc-testimonials-style2 .content {
  padding: 150px 0;
  border-top: 1px solid #ccc; }
  .s-project-pg-style2 .tc-testimonials-style2 .content .testi-slider {
    position: relative;
    overflow: hidden; }
    .s-project-pg-style2 .tc-testimonials-style2 .content .testi-slider .testi-card {
      text-align: center; }
      .s-project-pg-style2 .tc-testimonials-style2 .content .testi-slider .testi-card .text {
        font-size: 70px;
        font-family: 'Instrument Serif', serif;
        line-height: 1.1; }

.s-project-pg-style2 .main-video {
  height: 900px;
  border-radius: 30px 30px 0 0;
  overflow: hidden; }
  .s-project-pg-style2 .main-video video {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%; }

.s-project-pg-style2 .tc-contact-style2 {
  position: relative;
  padding: 150px 0 0;
  background-color: #F1EEE9;
  z-index: 30;
  margin-top: -30px;
  border-radius: 30px 30px 0 0; }
  .s-project-pg-style2 .tc-contact-style2 .form {
    display: block; }
    .s-project-pg-style2 .tc-contact-style2 .form label {
      display: block;
      font-size: 10px;
      color: #000;
      text-transform: uppercase; }
    .s-project-pg-style2 .tc-contact-style2 .form .form-control {
      border: 0;
      background-color: transparent;
      border-radius: 0;
      border-bottom: 1px solid #ccc;
      padding: 10px 0;
      font-size: 14px; }
      .s-project-pg-style2 .tc-contact-style2 .form .form-control:hover, .s-project-pg-style2 .tc-contact-style2 .form .form-control:focus {
        border-color: #000; }
  .s-project-pg-style2 .tc-contact-style2 .marq-content {
    position: relative;
    cursor: none; }
    .s-project-pg-style2 .tc-contact-style2 .marq-content * {
      cursor: none; }
    .s-project-pg-style2 .tc-contact-style2 .marq-content .float-cursor {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%) scale(0);
      -ms-transform: translate(-50%, -50%) scale(0);
      transform: translate(-50%, -50%) scale(0);
      opacity: 0;
      width: 160px;
      height: 160px;
      background-color: var(--color-orange1);
      color: #fff;
      border-radius: 50%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      font-size: 16px;
      z-index: 10;
      -webkit-transition: opacity 0.5s linear, -webkit-transform 0.1s ease;
      transition: opacity 0.5s linear, -webkit-transform 0.1s ease;
      -o-transition: opacity 0.5s linear, transform 0.1s ease;
      transition: opacity 0.5s linear, transform 0.1s ease;
      transition: opacity 0.5s linear, transform 0.1s ease, -webkit-transform 0.1s ease;
      pointer-events: none;
      -webkit-box-shadow: 0 0 30px #0001;
      box-shadow: 0 0 30px #0001; }
      .s-project-pg-style2 .tc-contact-style2 .marq-content .float-cursor .icon {
        font-size: 70px; }
      .s-project-pg-style2 .tc-contact-style2 .marq-content .float-cursor.show {
        opacity: 1;
        -webkit-transform: translate(-50%, -50%) scale(1);
        -ms-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1); }
    .s-project-pg-style2 .tc-contact-style2 .marq-content .marq-slider {
      position: relative;
      overflow: hidden;
      padding: 200px 0 150px; }
      .s-project-pg-style2 .tc-contact-style2 .marq-content .marq-slider .swiper-wrapper {
        -webkit-transition-timing-function: linear !important;
        -o-transition-timing-function: linear !important;
        transition-timing-function: linear !important;
        position: relative; }
        .s-project-pg-style2 .tc-contact-style2 .marq-content .marq-slider .swiper-wrapper .swiper-slide {
          width: -webkit-max-content !important;
          width: -moz-max-content !important;
          width: max-content !important; }
      .s-project-pg-style2 .tc-contact-style2 .marq-content .marq-slider a {
        font-size: 150px;
        line-height: 150px;
        font-weight: 500;
        -webkit-text-stroke: 1px #000;
        color: transparent; }
        .s-project-pg-style2 .tc-contact-style2 .marq-content .marq-slider a:hover {
          color: #000; }

@media screen and (max-width: 991px) {
  .s-project-pg-style2 .s-header-style2 {
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    min-height: -webkit-max-content;
    min-height: -moz-max-content;
    min-height: max-content; }
    .s-project-pg-style2 .s-header-style2 .title {
      font-size: 50px;
      line-height: 60px; }
  .s-project-pg-style2 .marq-slider a {
    font-size: 50px !important; }
  .s-project-pg-style2 .tc-main-info-style2 .imgs .lg-img {
    height: 400px; }
  .s-project-pg-style2 .tc-challenge-style2 .content {
    padding: 15px; }
    .s-project-pg-style2 .tc-challenge-style2 .content .case-card .img {
      height: 400px; }
  .s-project-pg-style2 .tc-testimonials-style2 .content .testi-slider .testi-card .text {
    font-size: 30px; }
  .s-project-pg-style2 .main-video {
    height: 400px; } }

/* --------------- portfolio pg style1 --------------- */
  /* {
  position: relative; } */
    .tc-main-slider-style1 {
    padding: 150px  0; }
      .tc-main-slider-style1 .main-slider {
        margin-top: 100px;
      position: relative;
      overflow: hidden; }
        .tc-main-slider-style1 .main-slider .swiper-slide-active .slider-card .content {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0); }
        .tc-main-slider-style1 .main-slider .arrows {
        position: absolute;
        right: 10vw;
        bottom: 80px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        background-color: #000;
        padding: 15px 10px;
        border-radius: 30px;
        z-index: 20;
        min-width: 140px; }
          .tc-main-slider-style1 .main-slider .arrows .swiper-button-prev,
          .tc-main-slider-style1 .main-slider .arrows .swiper-button-next {
          position: static;
          margin: 0;
          height: -webkit-max-content;
          height: -moz-max-content;
          height: max-content; }
            .tc-main-slider-style1 .main-slider .arrows .swiper-button-prev::after,
            .tc-main-slider-style1 .main-slider .arrows .swiper-button-next::after {
            font-size: 10px;
            color: #fff; }
        .tc-main-slider-style1 .main-slider .swiper-pagination {
        position: absolute;
        color: #fff;
        left: auto;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        right: calc(10vw + 58px);
        bottom: 90px;
        z-index: 50; }
        .tc-main-slider-style1 .main-slider .slider-card {
        position: relative;
        height: 800px;
        min-height: -webkit-max-content;
        min-height: -moz-max-content;
        min-height: max-content;
        /* border-radius: 30px; */
        overflow: hidden;
        }
          .tc-main-slider-style1 .main-slider .slider-card::after {
          position: absolute;
          content: "";
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-image: -webkit-gradient(linear, left bottom, left top, from(#000000af), color-stop(#0000005c), to(transparent));
          background-image: -webkit-linear-gradient(bottom, #000000af, #0000005c, transparent);
          background-image: -o-linear-gradient(bottom, #000000af, #0000005c, transparent);
          background-image: linear-gradient(to top, #000000af, #0000005c, transparent);
          z-index: 1; }
          .tc-main-slider-style1 .main-slider .slider-card .bg {
          width: 100%;
          height: 100%;
          -o-object-fit: cover;
          object-fit: cover;
          z-index: 0; }
          .tc-main-slider-style1 .main-slider .slider-card .content {
          position: absolute;
          left: 0;
          bottom: 100px;
          width: 80%;
          color: #fff;
          z-index: 5;
          padding: 70px 0;
          -webkit-transform: translateY(100px);
          -ms-transform: translateY(100px);
          transform: translateY(100px);
          opacity: 1;
          -webkit-transition: all 0.6s ease;
          -o-transition: all 0.6s ease;
          transition: all 0.6s ease; }
    .tc-latest-posts-style1 .content {
    position: relative;
    padding: 150px 0;
    border-bottom: 1px solid #ccc; }
      .tc-latest-posts-style1 .content .arrows {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content; }
        .tc-latest-posts-style1 .content .arrows .swiper-button-prev,
        .tc-latest-posts-style1 .content .arrows .swiper-button-next {
        position: static;
        margin: 0;
        -webkit-margin-start: 40px;
        margin-inline-start: 40px; }
          .tc-latest-posts-style1 .content .arrows .swiper-button-prev::after,
          .tc-latest-posts-style1 .content .arrows .swiper-button-next::after {
          font-size: 18px;
          color: #000; }
      .tc-latest-posts-style1 .content .posts-slider {
      position: relative;
      overflow: hidden; }
      .tc-latest-posts-style1 .content .post-card:hover .img img {
      -webkit-transform: scale(1.5);
      -ms-transform: scale(1.5);
      transform: scale(1.5); }
      .tc-latest-posts-style1 .content .post-card .img img {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
      -webkit-transition: all 10s linear;
      -o-transition: all 10s linear;
      transition: all 10s linear; }
    .tc-filter-posts-style1 {
    position: relative;
    padding: 150px 0; }
      .tc-filter-posts-style1 .filter .links a {
      font-size: 18px;
      line-height: 24px;
      color: #666;
      background-color: transparent;
      padding: 0;
      -webkit-margin-end: 50px;
      margin-inline-end: 50px;
      text-transform: uppercase; }
        .tc-filter-posts-style1 .filter .links a.active {
        color: var(--color-orange1); }
      .tc-filter-posts-style1 .search-group {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border-bottom: 1px solid #222; }
        .tc-filter-posts-style1 .search-group .form-control {
        border: 0;
        border-radius: 0;
        background-color: transparent;
        padding: 10px 0;
        font-size: 14px; }
        .tc-filter-posts-style1 .search-group button {
        border: 0;
        background-color: transparent; }

@media screen and (max-width: 991px) {
    .tc-main-slider-style1 {
    padding-top: 100px; }
    .fsz-45 {
    font-size: 25px !important; }
    .tc-main-slider-style1 .main-slider .slider-card {
    height: 400px; }
    .tc-main-slider-style1 .main-slider .arrows {
    bottom: 15px; }
    .tc-main-slider-style1 .main-slider .swiper-pagination {
    bottom: 25px; }
    .tc-latest-posts-style1 .content .arrows .swiper-button-prev,
    .tc-latest-posts-style1 .content .arrows .swiper-button-next {
    -webkit-margin-start: 0;
    margin-inline-start: 0;
    -webkit-margin-end: 30px;
    margin-inline-end: 30px; }
    .tc-latest-posts-style1 .content {
    padding: 100px 0; }
    .tc-filter-posts-style1 {
    padding: 100px 0; }
      .tc-filter-posts-style1 .filter .links a {
      font-size: 12px;
      -webkit-margin-end: 25px;
      margin-inline-end: 25px;
      font-weight: bold; } }

/* --------------- portfolio pg style1 --------------- */
   {
  position: relative; }
     .tc-main-img-style1 {
    position: relative; }
       .tc-main-img-style1 .main-img {
      height: 900px;
      /* border-radius: 30px 30px 0 0; */
      overflow: hidden;
      }
         .tc-main-img-style1 .main-img::after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: -webkit-gradient(linear, left bottom, left top, from(#000000c8), color-stop(#00000079), to(transparent));
        background-image: -webkit-linear-gradient(bottom, #000000c8, #00000079, transparent);
        background-image: -o-linear-gradient(bottom, #000000c8, #00000079, transparent);
        background-image: linear-gradient(to top, #000000c8, #00000079, transparent); }
         .tc-main-img-style1 .main-img img {
        max-height: unset;
        height: 200%;
        -o-object-position: top;
        object-position: top;
        margin-top: -25%;
        -webkit-transition: all linear;
        -o-transition: all linear;
        transition: all linear; }
       .tc-main-img-style1 .content {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      color: #fff;
      z-index: 5;
      padding: 120px 0;
      text-align: center; }
         .tc-main-img-style1 .content .title {
        font-size: 90px;
        font-weight: 500;
        line-height: 100px;
        letter-spacing: -0.03em; }
     .tc-post-content-style1 {
    position: relative;
    padding: 210px 0;
    /* background-color: #fff; */
    border-radius: 30px 30px 0 0;
    margin-top: -30px;
    z-index: 20;
    }
       .tc-post-content-style1 .breadcromb {
      padding: 5px 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      text-transform: capitalize;
      margin-bottom: 90px; }
         .tc-post-content-style1 .breadcromb .icon {
        margin: 0 5px; }
         .tc-post-content-style1 .breadcromb a:last-of-type {
        opacity: 0.7; }
       .tc-post-content-style1 .lg-letter {
      font-size: 90px;
      line-height: 55px;
      letter-spacing: -0.03em;
      color: #000;
      float: left;
      -webkit-margin-end: 30px;
      margin-inline-end: 30px; }
       .tc-post-content-style1 .share-content .tags a {
      font-size: 12px;
      color: #000;
      background-color: #EBEBF3;
      padding: 5px 10px;
      border-radius: 30px;
      -webkit-margin-end: 10px;
      margin-inline-end: 10px;
      font-weight: 500; }
         .tc-post-content-style1 .share-content .tags a:hover {
        background-color: var(--color-orange1);
        color: #fff; }
       .tc-post-content-style1 .share-content .share {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
         .tc-post-content-style1 .share-content .share .links a {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        -webkit-margin-start: 10px;
        margin-inline-start: 10px;
        border-radius: 50%;
        border: 1px solid #ccc;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        font-size: 13px; }
           .tc-post-content-style1 .share-content .share .links a:hover {
          border-color: var(--color-orange1);
          background-color: var(--color-orange1);
          color: #fff; }
       .tc-post-content-style1 .editor {
      margin-top: 110px;
      position: relative;
      padding: 80px 0;
      border-top: 1px solid #ccc; }
         .tc-post-content-style1 .editor .img {
        width: 110px;
        height: 110px;
        border-radius: 50%;
        overflow: hidden; }
         .tc-post-content-style1 .editor .social-links {
        position: relative;
        margin-top: 30px; }
           .tc-post-content-style1 .editor .social-links a {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          -webkit-margin-end: 10px;
          margin-inline-end: 10px;
          border-radius: 50%;
          border: 1px solid #ccc;
          display: -webkit-inline-box;
          display: -ms-inline-flexbox;
          display: inline-flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          font-size: 13px; }
             .tc-post-content-style1 .editor .social-links a:hover {
            border-color: var(--color-orange1);
            background-color: var(--color-orange1);
            color: #fff; }
       .tc-post-content-style1 .related-posts {
      position: relative;
      padding: 110px 0 30px;
      border-top: 1px solid #ccc; }
         .tc-post-content-style1 .related-posts .arrows {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content; }
           .tc-post-content-style1 .related-posts .arrows .swiper-button-prev,
           .tc-post-content-style1 .related-posts .arrows .swiper-button-next {
          position: static;
          margin: 0;
          -webkit-margin-start: 40px;
          margin-inline-start: 40px; }
             .tc-post-content-style1 .related-posts .arrows .swiper-button-prev::after,
             .tc-post-content-style1 .related-posts .arrows .swiper-button-next::after {
            font-size: 18px;
            color: #000; }
         .tc-post-content-style1 .related-posts .related-slider {
        position: relative;
        overflow: hidden; }
     .tc-comments-style1 {
    position: relative;
    background-color: #F1EEE9;
    border-radius: 30px 30px 0 0;
    margin-top: -30px;
    z-index: 40;
    padding: 110px 0; }
       .tc-comments-style1 .form-control {
      border: 0;
      border-bottom: 1px solid #ccc;
      border-radius: 0;
      background-color: transparent;
      padding: 10px 0; }
         .tc-comments-style1 .form-control:hover,    .tc-comments-style1 .form-control:focus {
        border-color: #000; }

@media screen and (max-width: 991px) {
     .th-450 {
    height: 300px !important; }
     .fsz-45 {
    font-size: 30px !important; }
     .tc-main-img-style1 .main-img {
    height: 400px; }
     .tc-main-img-style1 .content .title {
    font-size: 40px;
    line-height: 60px; }
     .tc-post-content-style1 .breadcromb {
    display: block; }
     .tc-post-content-style1 .share-content .tags a {
    margin-bottom: 10px; }
     .tc-post-content-style1 .related-posts .arrows .swiper-button-prev,
     .tc-post-content-style1 .related-posts .arrows .swiper-button-next {
    -webkit-margin-start: 0;
    margin-inline-start: 0;
    -webkit-margin-end: 30px;
    margin-inline-end: 30px; }
     .comment-replay-cont .d-flex {
    display: block !important; }
     .comment-replay-cont .ms-90 {
    -webkit-margin-start: 30px !important;
    margin-inline-start: 30px !important; } }

/* --------------- portfolio pg style1 --------------- */
.contact-pg-style1 {
  position: relative; }
  .contact-pg-style1 .social-icons a {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid #ccc;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 5px; }
    .contact-pg-style1 .social-icons a:hover {
      background-color: var(--color-orange1);
      border-color: var(--color-orange1);
      color: #fff; }
  .contact-pg-style1 .tc-map-style1 {
    position: relative;
    padding: 90px 4vw 120px;
    /* border-radius: 0 0 30px 30px; */
    margin-bottom: -30px;
    background-color: #fff;
    z-index: 10;
    }
    .contact-pg-style1 .tc-map-style1 .map-card {
      border-radius: 15px;
      overflow: hidden;
      height: 700px; }
      .contact-pg-style1 .tc-map-style1 .map-card iframe {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        margin-bottom: -6px;
        -webkit-filter: grayscale(0.7);
        filter: grayscale(0.7); }
  .contact-pg-style1 .tc-contact-form-style1 {
    position: relative;
    padding: 150px 0;
    /* background-color: #F1EEE9; */
    overflow: hidden;
    }
    .contact-pg-style1 .tc-contact-form-style1 .form {
      display: block;
      color: #fff;
      }
      .contact-pg-style1 .tc-contact-form-style1 .form label {
        display: block;
        font-size: 10px;
        color: #fff;
        text-transform: uppercase;
        }
      .contact-pg-style1 .tc-contact-form-style1 .form .form-control {
        border: 0;
        background-color: black;
        border-radius: 0;
        border-bottom: 1px solid #ccc;
        padding: 10px 0;
        font-size: 14px;
        color: #fff;
        }
      .contact-pg-style1 .tc-contact-form-style1 .form .form-control::placeholder{
        color: #fff;
      }
        .contact-pg-style1 .tc-contact-form-style1 .form .form-control:hover, .contact-pg-style1 .tc-contact-form-style1 .form .form-control:focus {
          border-color: #000; }
    .contact-pg-style1 .tc-contact-form-style1 .shap {
      position: absolute;
      bottom: -10px;
      left: 0;
      height: 55%; }

@media screen and (max-width: 991px) {
  .contact-pg-style1 .tc-map-style1 .map-card {
    height: 400px; } }

/* --------------- about pg style1 --------------- */
.about-pg-style1 {
  position: relative;
  /* ------ navbar ------- */
  /* ------ header ------- */
  /* --------------- about style7 --------------- */
  /* --------------- process style2 --------------- */
  /* --------------- footer style2 --------------- */
  /* --------------- awards style2 --------------- */ }
  .about-pg-style1 .inner-navbar-style1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99; }
    .about-pg-style1 .inner-navbar-style1 .navbar-nav .nav-link {
      color: #fff; }
    .about-pg-style1 .inner-navbar-style1 .nav-side a {
      color: #fff; }
  @media screen and (max-width: 991px) {
    .about-pg-style1 .inner-navbar-style1 {
      position: relative;
      background-color: #000;
      padding: 15px; } }
  .about-pg-style1 .tc-header-preview {
    position: relative; }
    .about-pg-style1 .tc-header-preview .slider-card {
      position: relative; }
      .about-pg-style1 .tc-header-preview .slider-card .img {
        height: 700px;
        position: relative;
        min-height: 100vh; }
        .about-pg-style1 .tc-header-preview .slider-card .img::after {
          position: absolute;
          content: "";
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: #000;
          opacity: 40%;
          pointer-events: none; }
      .about-pg-style1 .tc-header-preview .slider-card .info {
        position: absolute;
        left: 0;
        bottom: 30px;
        width: 100%;
        z-index: 20;
        color: #fff; }
        .about-pg-style1 .tc-header-preview .slider-card .info h1 {
          font-size: calc(8vw + 15px);
          font-weight: 500;
          line-height: 1;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }
    .about-pg-style1 .tc-header-preview .features-txt {
      font-size: 16px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      color: #fff;
      margin-top: 50px; }
    .about-pg-style1 .tc-header-preview .rotate-box {
      position: relative;
      width: 220px;
      height: 220px;
      display: block;
      margin: 0 90px 0 auto;
      bottom: -80px; }
      .about-pg-style1 .tc-header-preview .rotate-box .num {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        font-size: 70px;
        font-family: 'Instrument Serif', serif; }
      .about-pg-style1 .tc-header-preview .rotate-box .rotate-text {
        -webkit-animation: rotateText 20s linear infinite;
        animation: rotateText 20s linear infinite;
        font-weight: 400;
        text-transform: uppercase;
        position: absolute;
        left: 0;
        border-radius: 50%; }
      .about-pg-style1 .tc-header-preview .rotate-box .rotate-circle {
        background-color: rgba(255, 255, 255, 0.1);
        -webkit-backdrop-filter: blur(75px);
        backdrop-filter: blur(75px); }
        .about-pg-style1 .tc-header-preview .rotate-box .rotate-circle svg {
          width: 220px;
          height: 220px;
          fill: #fff;
          -webkit-transform: scale(1.2);
          -ms-transform: scale(1.2);
          transform: scale(1.2); }

@-webkit-keyframes rotateText {
  0% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); } }

@keyframes rotateText {
  0% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); } }
    .about-pg-style1 .tc-header-preview .float-social-links {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      left: 1vw;
      z-index: 20; }
      .about-pg-style1 .tc-header-preview .float-social-links a {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        background-color: #00000045;
        font-size: 16px;
        color: #fff;
        margin: 10px; }
        .about-pg-style1 .tc-header-preview .float-social-links a:hover {
          background-color: var(--color-orange1); }
    .about-pg-style1 .tc-header-preview .float-icons {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      right: 1vw;
      z-index: 20; }
      .about-pg-style1 .tc-header-preview .float-icons a {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        background-color: #00000045;
        font-size: 16px;
        color: #fff;
        margin: 10px; }
        .about-pg-style1 .tc-header-preview .float-icons a:hover {
          background-color: var(--color-orange1); }
  @media screen and (max-width: 991px) {
    .about-pg-style1 .tc-header-preview {
      overflow: hidden; }
      .about-pg-style1 .tc-header-preview .features-txt {
        display: block;
        line-height: 2.2; }
      .about-pg-style1 .tc-header-preview .info {
        padding-bottom: 100px; }
      .about-pg-style1 .tc-header-preview .float-icons {
        top: 50px;
        width: 100%; }
        .about-pg-style1 .tc-header-preview .float-icons ul {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center; }
          .about-pg-style1 .tc-header-preview .float-icons ul a {
            width: 35px;
            height: 35px;
            font-size: 12px; }
      .about-pg-style1 .tc-header-preview .float-social-links {
        top: auto;
        bottom: 0;
        width: 100%; }
        .about-pg-style1 .tc-header-preview .float-social-links ul {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center; }
          .about-pg-style1 .tc-header-preview .float-social-links ul a {
            width: 35px;
            height: 35px;
            font-size: 12px; }
      .about-pg-style1 .tc-header-preview .slider-card .img {
        height: 800px; }
      .about-pg-style1 .tc-header-preview .rotate-box {
        display: none; }
      .about-pg-style1 .tc-header-preview .slider-card .img {
        height: 600px; } }
  .about-pg-style1 .tc-about-style7 {
    position: relative;
    padding: 160px 0; }
    .about-pg-style1 .tc-about-style7 .rotate-box {
      position: relative;
      width: 220px;
      height: 220px;
      display: block;
      margin: 0 auto 30px; }
      .about-pg-style1 .tc-about-style7 .rotate-box .icon {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        height: 80px; }
      .about-pg-style1 .tc-about-style7 .rotate-box .rotate-text {
        -webkit-animation: rotateText 20s linear infinite;
        animation: rotateText 20s linear infinite;
        font-weight: 300;
        text-transform: uppercase;
        position: absolute;
        left: 0;
        border-radius: 50%; }
      .about-pg-style1 .tc-about-style7 .rotate-box .rotate-circle svg {
        width: 220px;
        height: 220px;
        fill: #000;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1); }

@keyframes rotateText {
  0% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); } }
    .about-pg-style1 .tc-about-style7 .numbers .number-card {
      position: relative;
      margin-bottom: 75px; }
      .about-pg-style1 .tc-about-style7 .numbers .number-card h2 {
        font-size: 60px;
        color: var(--color-orange1);
        font-weight: 400;
        margin-bottom: 15px; }
      .about-pg-style1 .tc-about-style7 .numbers .number-card small {
        font-size: 16px;
        text-transform: uppercase;
        color: #666; }
  @media screen and (max-width: 991px) {
    .about-pg-style1 .tc-about-style7 {
      padding: 50px 0;
      overflow: hidden; } }
  .about-pg-style1 .tc-process-style2 {
    position: relative;
    padding: 110px 0;
    overflow: hidden; }
    .about-pg-style1 .tc-process-style2 .container {
      position: relative;
      z-index: 10; }
    .about-pg-style1 .tc-process-style2 .bg {
      position: absolute;
      left: 0;
      top: -15%;
      width: 100%;
      height: 130%;
      -o-object-fit: cover;
      object-fit: cover;
      /* background-image: url("../img/prc_bg.png"); */
      background-size: cover;
      background-repeat: no-repeat;
      max-height: inherit; }
    .about-pg-style1 .tc-process-style2 .accordion-item {
      border: 0;
      border-radius: 20px;
      margin-bottom: 5px; }
      .about-pg-style1 .tc-process-style2 .accordion-item .accordion-button {
        color: #000;
        background-color: transparent;
        -webkit-box-shadow: none;
        box-shadow: none;
        border-radius: 0;
        padding: 30px;
        border: 0; }
        .about-pg-style1 .tc-process-style2 .accordion-item .accordion-button:not(.collapsed) h3 {
          color: var(--color-orange1); }
        .about-pg-style1 .tc-process-style2 .accordion-item .accordion-button:not(.collapsed)::after {
          background-image: none;
          content: "\f068";
          font-family: "Font Awesome 5 Pro";
          -webkit-transform: rotate(0) !important;
          -ms-transform: rotate(0) !important;
          transform: rotate(0) !important; }
        .about-pg-style1 .tc-process-style2 .accordion-item .accordion-button h3 {
          font-size: 24px; }
        .about-pg-style1 .tc-process-style2 .accordion-item .accordion-button .num {
          font-size: 24px;
          color: #999;
          font-weight: 200;
          -webkit-margin-end: 30px;
          margin-inline-end: 30px;
          font-family: 'Instrument Serif', serif; }
        .about-pg-style1 .tc-process-style2 .accordion-item .accordion-button::after {
          background-image: none;
          content: "\f067";
          font-family: "Font Awesome 5 Pro";
          -webkit-transform: rotate(0) !important;
          -ms-transform: rotate(0) !important;
          transform: rotate(0) !important; }
      .about-pg-style1 .tc-process-style2 .accordion-item .accordion-body {
        -webkit-padding-start: 85px;
        padding-inline-start: 85px;
        -webkit-padding-end: 50px;
        padding-inline-end: 50px;
        padding-top: 0;
        padding-bottom: 30px; }
        .about-pg-style1 .tc-process-style2 .accordion-item .accordion-body .text {
          font-size: 15px;
          color: #666; }
    .about-pg-style1 .tc-process-style2 .imgs {
      position: relative;
      /* background-image: url(../img/bubbles.png); */
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center; }
      .about-pg-style1 .tc-process-style2 .imgs .img {
        position: relative;
        margin: 0 auto; }
        .about-pg-style1 .tc-process-style2 .imgs .img img {
          border-radius: 50%; }
        .about-pg-style1 .tc-process-style2 .imgs .img:nth-of-type(1) {
          width: 360px;
          height: 360px;
          border-radius: 50%;
          -webkit-transform: translateX(50px);
          -ms-transform: translateX(50px);
          transform: translateX(50px); }
          .about-pg-style1 .tc-process-style2 .imgs .img:nth-of-type(1) .txt {
            font-size: 35px;
            position: absolute;
            top: 65px;
            right: -65px;
            z-index: 10;
            border: 1px solid #ccc;
            background-color: #F1EEE9;
            border-radius: 15px;
            padding: 0 30px;
            text-transform: capitalize; }
        .about-pg-style1 .tc-process-style2 .imgs .img:nth-of-type(2) {
          position: absolute;
          right: -100px;
          top: 150px;
          width: 160px;
          height: 160px;
          border-radius: 50%; }
          .about-pg-style1 .tc-process-style2 .imgs .img:nth-of-type(2)::after {
            position: absolute;
            content: "";
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-image: -webkit-gradient(linear, left bottom, left top, from(#000000c5), to(transparent));
            background-image: -webkit-linear-gradient(bottom, #000000c5, transparent);
            background-image: -o-linear-gradient(bottom, #000000c5, transparent);
            background-image: linear-gradient(to top, #000000c5, transparent);
            border-radius: 50%; }
          .about-pg-style1 .tc-process-style2 .imgs .img:nth-of-type(2) .txt {
            font-size: 25px;
            position: absolute;
            bottom: 15px;
            right: 0;
            z-index: 10;
            color: #fff;
            text-transform: capitalize; }
        .about-pg-style1 .tc-process-style2 .imgs .img:nth-of-type(3) {
          position: absolute;
          left: 70px;
          top: 300px;
          width: 220px;
          height: 220px;
          border-radius: 50%; }
          .about-pg-style1 .tc-process-style2 .imgs .img:nth-of-type(3)::after {
            position: absolute;
            content: "";
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-image: -webkit-gradient(linear, left bottom, left top, from(#000000c5), to(transparent));
            background-image: -webkit-linear-gradient(bottom, #000000c5, transparent);
            background-image: -o-linear-gradient(bottom, #000000c5, transparent);
            background-image: linear-gradient(to top, #000000c5, transparent);
            border-radius: 50%; }
          .about-pg-style1 .tc-process-style2 .imgs .img:nth-of-type(3) .txt {
            font-size: 30px;
            position: absolute;
            bottom: 15px;
            right: 50%;
            -webkit-transform: translateX(50%);
            -ms-transform: translateX(50%);
            transform: translateX(50%);
            z-index: 10;
            color: #fff;
            text-transform: capitalize; }
        .about-pg-style1 .tc-process-style2 .imgs .img:nth-of-type(4) {
          width: 240px;
          height: 240px;
          border-radius: 50%;
          margin-top: 40px;
          -webkit-transform: translateX(100%);
          -ms-transform: translateX(100%);
          transform: translateX(100%); }
          .about-pg-style1 .tc-process-style2 .imgs .img:nth-of-type(4) .txt {
            font-size: 30px;
            position: absolute;
            top: 65px;
            left: -65px;
            z-index: 10;
            background-color: #000;
            color: #fff;
            border-radius: 15px;
            padding: 5px 30px;
            text-transform: capitalize; }
  @media screen and (max-width: 991px) {
    .about-pg-style1 .tc-process-style2 {
      padding: 80px 0; }
      .about-pg-style1 .tc-process-style2 .imgs {
        margin-top: 50px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
        .about-pg-style1 .tc-process-style2 .imgs .img {
          width: 48% !important;
          height: 250px !important;
          border-radius: 30px !important;
          margin: 1% !important;
          position: relative !important;
          top: 0 !important;
          left: 0 !important;
          right: 0 !important;
          bottom: 0 !important;
          -webkit-transform: translate(0) !important;
          -ms-transform: translate(0) !important;
          transform: translate(0) !important; }
          .about-pg-style1 .tc-process-style2 .imgs .img::after {
            position: absolute;
            content: "";
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-image: -webkit-gradient(linear, left bottom, left top, from(#000000c5), to(transparent));
            background-image: -webkit-linear-gradient(bottom, #000000c5, transparent);
            background-image: -o-linear-gradient(bottom, #000000c5, transparent);
            background-image: linear-gradient(to top, #000000c5, transparent);
            border-radius: 30px !important; }
          .about-pg-style1 .tc-process-style2 .imgs .img img {
            border-radius: 30px !important; }
          .about-pg-style1 .tc-process-style2 .imgs .img .txt {
            font-size: 30px !important;
            position: absolute !important;
            top: auto !important;
            bottom: 15px !important;
            right: 50% !important;
            -webkit-transform: translateX(50%) !important;
            -ms-transform: translateX(50%) !important;
            transform: translateX(50%) !important;
            z-index: 10 !important;
            color: #fff !important;
            text-transform: capitalize !important;
            background-color: transparent !important;
            border: 0 !important; } }
  .about-pg-style1 .tc-projects-style2 {
    position: relative;
    padding-top: 110px; }
    .about-pg-style1 .tc-projects-style2 .tabs-links .nav-pills .nav-link {
      background-color: transparent;
      padding: 0;
      -webkit-margin-end: 40px;
      margin-inline-end: 40px;
      font-size: 18px;
      font-weight: 500;
      border-radius: 0;
      text-transform: capitalize;
      color: #666; }
      .about-pg-style1 .tc-projects-style2 .tabs-links .nav-pills .nav-link.active, .about-pg-style1 .tc-projects-style2 .tabs-links .nav-pills .nav-link:hover {
        color: #000; }
    .about-pg-style1 .tc-projects-style2 .projects-slider-content {
      padding: 0 3vw;
      position: relative;
      overflow: hidden; }
      .about-pg-style1 .tc-projects-style2 .projects-slider-content .projects-slider {
        position: relative;
        overflow: hidden;
        padding-bottom: 120px; }
        .about-pg-style1 .tc-projects-style2 .projects-slider-content .projects-slider .controls {
          position: absolute;
          bottom: 0;
          width: 100%; }
          .about-pg-style1 .tc-projects-style2 .projects-slider-content .projects-slider .controls .swiper-pagination {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            bottom: 0;
            position: static; }
            .about-pg-style1 .tc-projects-style2 .projects-slider-content .projects-slider .controls .swiper-pagination .swiper-pagination-bullet {
              margin: 0;
              -webkit-box-flex: 1;
              -ms-flex-positive: 1;
              flex-grow: 1;
              height: 1px;
              border-radius: 0;
              background-color: #ccc;
              opacity: 1; }
              .about-pg-style1 .tc-projects-style2 .projects-slider-content .projects-slider .controls .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
                height: 3px;
                background-color: var(--color-orange1);
                margin-top: -1px; }
    .about-pg-style1 .tc-projects-style2 .project-card {
      position: relative; }
      .about-pg-style1 .tc-projects-style2 .project-card:hover .img .arrow {
        background-color: var(--color-orange1);
        color: #fff; }
      .about-pg-style1 .tc-projects-style2 .project-card .img {
        height: 650px;
        position: relative;
        border-radius: 0 20px 20px 20px;
        overflow: hidden;
        display: block; }
        .about-pg-style1 .tc-projects-style2 .project-card .img::after {
          position: absolute;
          content: "";
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: #000;
          opacity: 0.3; }
        .about-pg-style1 .tc-projects-style2 .project-card .img .year {
          position: absolute;
          top: 0;
          left: 0;
          width: 40%;
          padding: 30px 20px 40px 10px;
          text-align: center;
          color: #000;
          z-index: 10; }
          .about-pg-style1 .tc-projects-style2 .project-card .img .year .txt {
            position: relative;
            z-index: 10;
            font-size: 18px; }
          .about-pg-style1 .tc-projects-style2 .project-card .img .year .bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%; }
        .about-pg-style1 .tc-projects-style2 .project-card .img .arrow {
          position: absolute;
          right: 20px;
          top: 20px;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background-color: #fff;
          font-size: 20px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease;
          z-index: 10; }
      .about-pg-style1 .tc-projects-style2 .project-card .info {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 90%;
        padding: 40px;
        color: #fff; }
        .about-pg-style1 .tc-projects-style2 .project-card .info .title {
          font-size: 35px;
          font-weight: 500; }
        .about-pg-style1 .tc-projects-style2 .project-card .info .tags {
          margin-bottom: 30px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex; }
          .about-pg-style1 .tc-projects-style2 .project-card .info .tags a {
            padding: 8px 20px;
            border-radius: 30px;
            background-color: #fff;
            color: #000;
            font-weight: 500;
            font-size: 12px; }
  @media screen and (max-width: 991px) {
    .about-pg-style1 .tc-projects-style2 {
      padding-top: 80px; }
      .about-pg-style1 .tc-projects-style2 .tabs-links .nav-pills .nav-link {
        -webkit-margin-end: 15px;
        margin-inline-end: 15px;
        font-size: 12px;
        font-weight: 600; }
      .about-pg-style1 .tc-projects-style2 .project-card .img {
        height: 400px; }
      .about-pg-style1 .tc-projects-style2 .project-card .info .title {
        font-size: 25px; } }
  .about-pg-style1 .tc-awards-style2 {
    position: relative;
    padding: 110px 0; }
    .about-pg-style1 .tc-awards-style2 .award-card {
      border: 1px solid #ccc;
      border-radius: 15px;
      padding: 30px;
      margin-top: 30px;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease; }
      .about-pg-style1 .tc-awards-style2 .award-card:hover {
        border-color: var(--color-orange1); }
        .about-pg-style1 .tc-awards-style2 .award-card:hover a {
          color: #000 !important;
          text-decoration: underline; }
      .about-pg-style1 .tc-awards-style2 .award-card .logo {
        height: 35px; }
      .about-pg-style1 .tc-awards-style2 .award-card .title {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: end;
        margin: 15px 0;
        min-height: 90px; }
  @media screen and (max-width: 991px) {
    .about-pg-style1 .tc-awards-style2 {
      padding: 80px 0; } }

/* --------------- services pg style1 --------------- */
.services-pg-style1 {
  position: relative;
  /* --------------- header style2 --------------- */
  /* --------------- services style1 --------------- */
  /* --------------- process style1 --------------- */
  /* --------------- testimonials style1 --------------- */ }
  .services-pg-style1 .tc-header-style2 {
    position: relative;
    padding: 120px 0; }
    .services-pg-style1 .tc-header-style2 .top-info h1 {
      font-size: 90px;
      font-weight: 600; }
      .services-pg-style1 .tc-header-style2 .top-info h1 span {
        color: var(--color-orange1);
        font-style: italic;
        font-weight: 400; }
    .services-pg-style1 .tc-header-style2 .top-info .award-wrapper {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 10px 40px;
      border: 1px solid #999;
      border-radius: 50px;
      margin-top: 70px; }
      .services-pg-style1 .tc-header-style2 .top-info .award-wrapper p {
        font-size: 18px; }
      .services-pg-style1 .tc-header-style2 .top-info .award-wrapper .line {
        width: 60px;
        height: 1px;
        background-color: #999;
        margin: 0 30px; }
    .services-pg-style1 .tc-header-style2 .top-info .facts-wrapper {
      position: relative;
      padding: 30px 40px; }
      .services-pg-style1 .tc-header-style2 .top-info .facts-wrapper * {
        position: relative;
        z-index: 10; }
      .services-pg-style1 .tc-header-style2 .top-info .facts-wrapper .num {
        font-size: 60px;
        font-weight: 400; }
      .services-pg-style1 .tc-header-style2 .top-info .facts-wrapper p {
        color: #666; }
      .services-pg-style1 .tc-header-style2 .top-info .facts-wrapper .line {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: 0; }
  @media screen and (max-width: 991px) {
    .services-pg-style1 .tc-header-style2 {
      padding-top: 80px; }
      .services-pg-style1 .tc-header-style2 .top-info h1 {
        font-size: 50px; }
      .services-pg-style1 .tc-header-style2 .top-info .award-wrapper p {
        font-size: 12px;
        -ms-flex-negative: 0;
        flex-shrink: 0; }
      .services-pg-style1 .tc-header-style2 .top-info .facts-wrapper {
        margin-top: 50px; } }
  .services-pg-style1 .tc-services-style1 {
    position: relative;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#F1EEE9), color-stop(#F1EEE999), to(transparent));
    background-image: -webkit-linear-gradient(top, #F1EEE9, #F1EEE999, transparent);
    background-image: -o-linear-gradient(top, #F1EEE9, #F1EEE999, transparent);
    background-image: linear-gradient(to bottom, #F1EEE9, #F1EEE999, transparent);
    border-radius: 50px 50px 0 0; }
    .services-pg-style1 .tc-services-style1 .title p {
      letter-spacing: 50px;
      opacity: 0;
      -webkit-transition: all 0.7s ease;
      -o-transition: all 0.7s ease;
      transition: all 0.7s ease;
      -webkit-transition-delay: 0.3s;
      -o-transition-delay: 0.3s;
      transition-delay: 0.3s; }
      .services-pg-style1 .tc-services-style1 .title p.animated {
        letter-spacing: 10px;
        opacity: 1; }
    .services-pg-style1 .tc-services-style1 .content {
      position: relative;
      z-index: 10; }
    .services-pg-style1 .tc-services-style1 .service-card {
      padding: 20px 60px 20px 30px;
      -webkit-border-start: 1px solid #ccc;
      border-inline-start: 1px solid #ccc;
      display: block; }
      .services-pg-style1 .tc-services-style1 .service-card:hover .arrow {
        background-color: #000;
        color: #fff; }
      .services-pg-style1 .tc-services-style1 .service-card .icon {
        color: var(--color-orange1);
        margin-bottom: 20px;
        font-size: 30px; }
      .services-pg-style1 .tc-services-style1 .service-card .img {
        height: 160px;
        border-radius: 10px;
        overflow: hidden; }
      .services-pg-style1 .tc-services-style1 .service-card .arrow {
        width: 35px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        border-radius: 50%;
        margin-top: 40px;
        border: 1px solid #ccc;
        -webkit-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease; }
    .services-pg-style1 .tc-services-style1 .ser-img {
      width: 100%;
      height: 1000px;
      margin-top: -550px; }
      .services-pg-style1 .tc-services-style1 .ser-img img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: bottom;
        object-position: bottom; }
  @media screen and (max-width: 991px) {
    .services-pg-style1 .tc-services-style1 .service-card {
      border: 0;
      padding: 0;
      margin-top: 50px !important; } }
  .services-pg-style1 .tc-process-style1 {
    position: relative;
    padding: 150px 0;
    background-color: #fff;
    z-index: 10;
    border-radius: 40px 40px 0 0;
    margin-top: -40px; }
    .services-pg-style1 .tc-process-style1 .c-line {
      position: absolute;
      bottom: 100px;
      right: 0;
      width: 50%;
      height: 550px;
      z-index: 10;
      pointer-events: none;
      -webkit-clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
      clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
      -webkit-transition: all 2s linear;
      -o-transition: all 2s linear;
      transition: all 2s linear; }
      .services-pg-style1 .tc-process-style1 .c-line.animated {
        -webkit-clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
        clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%); }
    .services-pg-style1 .tc-process-style1 .accordion-item {
      border: 0;
      border-radius: 0; }
      .services-pg-style1 .tc-process-style1 .accordion-item .accordion-button {
        color: #000;
        background-color: transparent;
        -webkit-box-shadow: none;
        box-shadow: none;
        border-top: 1px solid #ccc;
        border-radius: 0;
        padding: 30px; }
        .services-pg-style1 .tc-process-style1 .accordion-item .accordion-button:not(.collapsed) {
          border-color: #000; }
          .services-pg-style1 .tc-process-style1 .accordion-item .accordion-button:not(.collapsed) h3 {
            color: var(--color-orange1); }
        .services-pg-style1 .tc-process-style1 .accordion-item .accordion-button h3 {
          font-size: 24px; }
        .services-pg-style1 .tc-process-style1 .accordion-item .accordion-button .num {
          font-size: 24px;
          color: #999;
          font-weight: 200;
          -webkit-margin-end: 30px;
          margin-inline-end: 30px; }
      .services-pg-style1 .tc-process-style1 .accordion-item .accordion-body {
        -webkit-padding-start: 85px;
        padding-inline-start: 85px;
        -webkit-padding-end: 50px;
        padding-inline-end: 50px;
        padding-top: 0;
        padding-bottom: 30px; }
        .services-pg-style1 .tc-process-style1 .accordion-item .accordion-body .text {
          font-size: 15px;
          color: #666; }
    .services-pg-style1 .tc-process-style1 .img {
      height: 550px;
      border-radius: 20px;
      overflow: hidden;
      -webkit-transform: rotate(-30deg) scale(1.3);
      -ms-transform: rotate(-30deg) scale(1.3);
      transform: rotate(-30deg) scale(1.3);
      -webkit-transition: all 1s ease;
      -o-transition: all 1s ease;
      transition: all 1s ease;
      -webkit-transition-delay: 0.4s;
      -o-transition-delay: 0.4s;
      transition-delay: 0.4s;
      opacity: 0; }
      .services-pg-style1 .tc-process-style1 .img.animated {
        -webkit-transform: rotate(15deg) scale(1);
        -ms-transform: rotate(15deg) scale(1);
        transform: rotate(15deg) scale(1);
        opacity: 1; }
  @media screen and (max-width: 991px) {
    .services-pg-style1 .tc-process-style1 {
      padding: 80px 0; }
      .services-pg-style1 .tc-process-style1 .accordion-item .accordion-button .num {
        display: none; }
      .services-pg-style1 .tc-process-style1 .accordion-item .accordion-button h3 {
        font-size: 21px; }
      .services-pg-style1 .tc-process-style1 .accordion-item .accordion-body {
        -webkit-padding-start: 30px;
        padding-inline-start: 30px; }
      .services-pg-style1 .tc-process-style1 .img {
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0);
        margin-top: 50px;
        height: 350px; } }
  .services-pg-style1 .tc-testimonials-style1 {
    position: relative;
    padding: 150px 0; }
    .services-pg-style1 .tc-testimonials-style1 .c-line {
      position: absolute;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 25%;
      z-index: 5;
      pointer-events: none; }
    .services-pg-style1 .tc-testimonials-style1 .lg-icon {
      font-size: 170px;
      -webkit-margin-start: -15px;
      margin-inline-start: -15px;
      display: block; }
    .services-pg-style1 .tc-testimonials-style1 .tc-clients-style1 {
      position: relative;
      overflow: hidden; }
      .services-pg-style1 .tc-testimonials-style1 .tc-clients-style1 .clients-slider1 {
        position: relative;
        overflow: hidden; }
      .services-pg-style1 .tc-testimonials-style1 .tc-clients-style1 .author {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding-top: 50px;
        margin-top: 80px;
        border-top: 1px solid #ccc; }
        .services-pg-style1 .tc-testimonials-style1 .tc-clients-style1 .author .au-img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          overflow: hidden;
          -ms-flex-negative: 0;
          flex-shrink: 0;
          -webkit-margin-end: 20px;
          margin-inline-end: 20px; }
      .services-pg-style1 .tc-testimonials-style1 .tc-clients-style1 .slider-controls {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 10px 20px;
        border-radius: 30px;
        border: 1px solid #ccc;
        position: absolute;
        right: 0;
        bottom: 0;
        background: #fff;
        z-index: 10; }
        .services-pg-style1 .tc-testimonials-style1 .tc-clients-style1 .slider-controls .swiper-button-next,
        .services-pg-style1 .tc-testimonials-style1 .tc-clients-style1 .slider-controls .swiper-button-prev {
          position: static;
          height: -webkit-max-content;
          height: -moz-max-content;
          height: max-content;
          margin: 0; }
          .services-pg-style1 .tc-testimonials-style1 .tc-clients-style1 .slider-controls .swiper-button-next::after,
          .services-pg-style1 .tc-testimonials-style1 .tc-clients-style1 .slider-controls .swiper-button-prev::after {
            color: #000;
            font-size: 12px; }
          .services-pg-style1 .tc-testimonials-style1 .tc-clients-style1 .slider-controls .swiper-button-next:hover::after,
          .services-pg-style1 .tc-testimonials-style1 .tc-clients-style1 .slider-controls .swiper-button-prev:hover::after {
            color: var(--color-orange1); }
        .services-pg-style1 .tc-testimonials-style1 .tc-clients-style1 .slider-controls .swiper-pagination {
          position: static;
          margin: 0 10px; }
          .services-pg-style1 .tc-testimonials-style1 .tc-clients-style1 .slider-controls .swiper-pagination .swiper-pagination-bullet {
            width: 8px;
            height: 8px;
            margin: 0 5px; }
            .services-pg-style1 .tc-testimonials-style1 .tc-clients-style1 .slider-controls .swiper-pagination .swiper-pagination-bullet .swiper-pagination-bullet-active {
              width: 10px;
              height: 10px; }
    .services-pg-style1 .tc-testimonials-style1 .marq-slider {
      position: relative;
      overflow: hidden;
      padding-top: 100px; }
      .services-pg-style1 .tc-testimonials-style1 .marq-slider .swiper-wrapper {
        -webkit-transition-timing-function: linear !important;
        -o-transition-timing-function: linear !important;
        transition-timing-function: linear !important;
        position: relative; }
        .services-pg-style1 .tc-testimonials-style1 .marq-slider .swiper-wrapper .swiper-slide {
          width: -webkit-max-content !important;
          width: -moz-max-content !important;
          width: max-content !important; }
      .services-pg-style1 .tc-testimonials-style1 .marq-slider a {
        font-size: 150px;
        font-weight: 500;
        -webkit-text-stroke: 1px #000;
        color: transparent; }
        .services-pg-style1 .tc-testimonials-style1 .marq-slider a:hover {
          color: #000; }
  @media screen and (max-width: 991px) {
    .services-pg-style1 .tc-testimonials-style1 {
      padding: 50px 0; }
      .services-pg-style1 .tc-testimonials-style1 .lg-icon {
        font-size: 120px; }
      .services-pg-style1 .tc-testimonials-style1 .marq-slider a {
        font-size: 50px; } }

/* --------------- footer style1 --------------- */
.tc-footer-style1 .foot-social a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
  -webkit-margin-end: 10px;
  margin-inline-end: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  font-size: 14px; }
  .tc-footer-style1 .foot-social a:hover {
    background-color: var(--color-orange1);
    border-color: var(--color-orange1);
    color: #fff; }

.tc-footer-style1 .footer-links a {
  font-size: 14px;
  color: #666;
  margin: 5px 0; }
  .tc-footer-style1 .footer-links a:hover {
    color: #000;
    text-decoration: underline; }

.tc-footer-style1 .foot {
  padding: 30px 0;
  border-top: 1px solid #ccc; }
  .tc-footer-style1 .foot .foot-links a {
    font-size: 14px;
    -webkit-margin-end: 4vw;
    margin-inline-end: 4vw; }
    .tc-footer-style1 .foot .foot-links a:last-of-type {
      margin: 0; }

 .tc-inner-header-style1 {
  padding-top: 150px;
  background: url("../../../assets/img/blog/blog1.jpg");
  position: relative;
  background-repeat: no-repeat;
    background-size: cover;
    background-position: center ;
  padding-bottom: 40px; }
 .tc-inner-header-style1 h1 {
    font-size: 250px;
    font-weight: 500;
    line-height: 300px;
    letter-spacing: -0.05em;
    color: #000; }

  .pagination {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }
    .pagination .page-link {
    border-radius: 50%;
    color: #000;
    border: 1px solid #ccc;
    margin: 0 5px;
    width: 45px;
    height: 45px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
      .pagination .page-link.active {
      border-color: var(--color-orange1);
      color: var(--color-orange1); }

@media screen and (max-width: 991px) {
    br {
    display: none; }
    .fsz-50 {
    font-size: 30px !important; }
    .fsz-40 {
    font-size: 25px !important; }
    .mb-90 {
    margin-bottom: 40px !important; }
    .fsz-30 {
    font-size: 18px !important; }
    .tc-inner-header-style1 h1 {
    font-size: 70px;
    line-height: 100px;
    margin-bottom: 30px; } }
  