/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap'); */
/* Define the font-face */
@font-face {
  font-family: 'futura';
  src: url('../../../innerpages/assets/fonts/futur.ttf') format('woff2'),
       url('../../../innerpages/assets/fonts/futur.ttf') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* Apply the font */
/* body {
  font-family: 'MyCustomFont', sans-serif;
} */

*, * button:focus { outline: 0px; margin: 0px; }
html::-webkit-scrollbar { height: 20px; width: 10px; background: rgb(241, 241, 241); border-radius: 0px; }
html::-webkit-scrollbar-thumb { background: rgb(153, 153, 153); border-radius: 10px; }
html::-webkit-scrollbar-corner { background: rgb(153, 153, 153); border-radius: 10px; }
body { font-family: "futura", sans-serif; text-decoration: none; font-size: 14px; overflow-x: hidden; position: relative; color: rgb(255, 255, 255); background-color: rgb(0, 0, 0); }
.swiper-container { overflow: hidden; }
.swiper-notification { display: none; }
.form-control:focus { box-shadow: none; }
.navbar { z-index: 99; }
h1, h2, h3 { font-weight: bold; margin: 0px; }
h4, h5, h6 { margin: 0px; }
.container, .container-fluid { position: relative; z-index: 5; }
.container, .container-lg, .container-md, .container-sm, .container-xl { padding-right: 15px; padding-left: 15px; max-width: 1330px !important; }
.row:not(.gx-0):not(.gx-1):not(.gx-2):not(.gx-3):not(.gx-4):not(.gx-5):not(.gx-lg-5) { margin-right: -15px; margin-left: -15px; }
.row:not(.gx-0):not(.gx-1):not(.gx-2):not(.gx-3):not(.gx-4):not(.gx-5):not(.gx-lg-5) > * { padding-right: 15px; padding-left: 15px; }
.row.gx-5, .row.gx-lg-5 { margin-right: -30px; margin-left: -30px; }
.row.gx-5 > *, .row.gx-lg-5 > * { padding-right: 30px; padding-left: 30px; }
body a:focus { outline: 0px; }
a:hover { color: inherit; text-decoration: none; }
body ul:not(.list-styled) { list-style-type: none; margin: 0px; padding: 0px; }
.btn-default:active, .btn-default:active:focus, .btn-default:active:hover, .btn-default:focus, .btn-default:hover, .btn-primary:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active, .btn:not(:disabled):not(.disabled):active { appearance: none; }
.btn:active, .btn:active:focus, .btn:active:hover, .btn:focus, .btn:hover, .btn:not(:disabled):not(.disabled).active, .btn:not(:disabled):not(.disabled):active { transition: all 0.6s ease 0s; outline: 0px; }
a, span, img { display: inline-block; transition: all 0.3s ease 0s; color: inherit; text-decoration: none; }
img { max-width: 100%; max-height: 100%; }
p { font-size: 14px; line-height: 1.7; margin: 0px; }
i, .bi { display: inline-flex; }
.text { font-size: 14px; line-height: 1.8; }
.small, small { font-size: 12px; display: inline-block; }
small.text { font-size: 12px; }
.section-padding { padding: 100px 0px; }
.df-center { display: flex !important; -webkit-box-align: center !important; align-items: center !important; -webkit-box-pack: center !important; justify-content: center !important; }
.dnf-center { display: inline-flex !important; -webkit-box-align: center !important; align-items: center !important; -webkit-box-pack: center !important; justify-content: center !important; }
.fm-playfair { font-family: "Playfair Display", serif; }
.loader-wrap { position: fixed; height: 100vh; width: 100%; left: 0px; top: 0px; display: flex; overflow: hidden; -webkit-box-align: center; align-items: center; -webkit-box-pack: center; justify-content: center; background: transparent; z-index: 2147483647; }
.loader-wrap .text-center { position: relative; z-index: 444; }
.loader-wrap .text-center .logo { width: 50px; margin-bottom: 30px; }
.loader-wrap svg { position: absolute; top: 0px; width: 100vw; height: 110vh; fill: rgb(241, 238, 233); }
.loader-wrap .loader-wrap-heading .load-text { font-size: 20px; font-weight: 200; letter-spacing: 10px; text-transform: uppercase; color: rgb(0, 0, 0); z-index: 20; }
.load-text span { animation: 1s ease 0s infinite alternate none running loading; }
.load-text span:nth-child(1) { animation-delay: 0s; }
.load-text span:nth-child(2) { animation-delay: 0.1s; }
.load-text span:nth-child(3) { animation-delay: 0.2s; }
.load-text span:nth-child(4) { animation-delay: 0.3s; }
.load-text span:nth-child(5) { animation-delay: 0.4s; }
.load-text span:nth-child(6) { animation-delay: 0.5s; }
.load-text span:nth-child(7) { animation-delay: 0.6s; }
@-webkit-keyframes loading { 
  0% { opacity: 1; }
  100% { opacity: 0; }
}
@keyframes loading { 
  0% { opacity: 1; }
  100% { opacity: 0; }
}
.swiper-wrapper.curs-scroll { cursor: none; }
.mouse-cursor { position: fixed; left: 0px; top: 0px; pointer-events: none; border-radius: 50%; transform: translateZ(0px); visibility: hidden; }
.cursor-inner { margin-left: -2px; margin-top: -2px; width: 5px; height: 5px; z-index: 10000001; background-color: rgba(3, 121, 164, 0.333); transition: width 0.3s ease-in-out 0s, height 0.3s ease-in-out 0s, margin 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s; opacity: 1; }
.cursor-inner.cursor-hover { opacity: 1; }
.cursor-outer { margin-left: -17px; margin-top: -17px; width: 34px; height: 34px; border: 1px solid rgba(3, 121, 164, 0.333); box-sizing: border-box; z-index: 10000000; transition: width 0.3s ease-in-out 0s, height 0.3s ease-in-out 0s, margin 0.3s ease-in-out 0s, background-color 0.3s ease 0s, opacity 0.3s ease 0s; opacity: 1; }
.cursor-outer.cursor-hover { margin-left: -25px; margin-top: -25px; width: 50px; height: 50px; border: 1px solid rgba(255, 255, 255, 0.6); background-color: rgba(3, 121, 164, 0.333) !important; opacity: 0.3 !important; }
.cursor-outer.cursor-scroll { margin-left: -35px; margin-top: -35px; width: 70px; height: 70px; border-color: transparent; background-color: rgb(255, 255, 255); opacity: 1; }
.dropdown-toggle::after { position: relative; top: 3px; opacity: 0.8; content: ""; display: inline-block; font-family: "Font Awesome 5 Pro"; border: 0px; line-height: 1; font-size: 0.8em; }
.dropdown-menu { min-width: 200px; padding: 0px; border-radius: 0px; border: 0px; box-shadow: rgba(0, 0, 0, 0.067) 0px 0px 20px; top: 100%; }
.dropdown-menu .dropdown-item { padding: 10px 20px; font-size: 14px; border-bottom: 1px solid rgba(153, 153, 153, 0.133); }
select:focus, input:focus { box-shadow: none !important; }
.to_top { position: fixed; z-index: 9999; bottom: 3vw; right: -80px; width: 50px; height: 50px; border-radius: 50%; color: rgb(153, 153, 153); border: 1px solid rgb(153, 153, 153); text-align: center; padding: 7px; line-height: 1; opacity: 0; transition: all 0.4s ease 0s; font-size: 18px; display: flex; -webkit-box-align: center; align-items: center; -webkit-box-pack: center; justify-content: center; }
.to_top small { display: block; text-transform: uppercase; transition: all 0.4s ease 0s; font-size: 8px; }
.to_top i { transition: all 0.4s ease 0s; }
.to_top.show { right: 3vw; opacity: 1; }
.rotate-center { animation: 100s linear 0s infinite normal both running rotate-center; }
.rotate-center.reverse { animation: 100s linear 0s infinite reverse both running rotate-center; }
@-webkit-keyframes rotate-center { 
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@keyframes rotate-center { 
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.slide-up-down { animation: 1.7s ease-in-out 0s infinite alternate both running slide_up_down; }
/* @-webkit-keyframes slide_up_down { 
  0% { transform: translateY(0px); }
  100% { transform: translateY(-20px); }
} */
/* @keyframes slide_up_down { 
  0% { transform: translateY(0px); }
  100% { transform: translateY(-20px); }
} */
.left_to_right_apperance { transform: scale(1.2); transition: all 0.8s ease-in-out 0.4s; clip-path: polygon(0px 0px, 0px 0px, 0px 100%, 0% 100%); }
.left_to_right_apperance.no_scale { transform: scale(1); }
.left_to_right_apperance.animated { transform: scale(1); clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0% 100%); }
.right_to_left_apperance { transform: scale(1.2); transition: all 0.8s ease-in-out 0.4s; clip-path: polygon(99% 0px, 100% 0px, 100% 100%, 100% 100%); }
.right_to_left_apperance.no_scale { transform: scale(1); }
.right_to_left_apperance.animated { transform: scale(1); clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0% 100%); }
.radius-1 { border-radius: 3px !important; }
.radius-2 { border-radius: 5px !important; }
.radius-3 { border-radius: 8px !important; }
.radius-4 { border-radius: 10px !important; }
.radius-5 { border-radius: 12px !important; }
.radius-6 { border-radius: 15px !important; }
.radius-7 { border-radius: 20px !important; }
.radius-8 { border-radius: 25px !important; }
.radius-9 { border-radius: 30px !important; }
.radius-10 { border-radius: 50px !important; }
.mt-10 { margin-top: 10px !important; }
.mt-15 { margin-top: 15px !important; }
.mt-20 { margin-top: 20px !important; }
.mt-30 { margin-top: 30px !important; }
.mt-40 { margin-top: 40px !important; }
.mt-50 { margin-top: 50px !important; }
.mt-60 { margin-top: 60px !important; }
.mt-70 { margin-top: 70px !important; }
.mt-80 { margin-top: 80px !important; }
.mt-90 { margin-top: 90px !important; }
.mt-100 { margin-top: 100px !important; }
.mt-120 { margin-top: 120px !important; }
.mt-200 { margin-top: 200px !important; }
.mb-10 { margin-bottom: 10px !important; }
.mb-15 { margin-bottom: 15px !important; }
.mb-20 { margin-bottom: 20px !important; }
.mb-30 { margin-bottom: 30px !important; }
.mb-40 { margin-bottom: 40px !important; }
.mb-50 { margin-bottom: 50px !important; }
.mb-60 { margin-bottom: 60px !important; }
.mb-70 { margin-bottom: 70px !important; }
.mb-80 { margin-bottom: 80px !important; }
.mb-90 { margin-bottom: 90px !important; }
.mb-100 { margin-bottom: 100px !important; }
.mb-120 { margin-bottom: 120px !important; }
.mb-150 { margin-bottom: 150px !important; }
.mb-200 { margin-bottom: 200px !important; }
.ms-10 { margin-inline-start: 10px !important; }
.ms-15 { margin-inline-start: 15px !important; }
.ms-20 { margin-inline-start: 20px !important; }
.ms-30 { margin-inline-start: 30px !important; }
.ms-40 { margin-inline-start: 40px !important; }
.ms-50 { margin-inline-start: 50px !important; }
.ms-60 { margin-inline-start: 60px !important; }
.ms-70 { margin-inline-start: 70px !important; }
.ms-80 { margin-inline-start: 80px !important; }
.ms-90 { margin-inline-start: 90px !important; }
.ms-100 { margin-inline-start: 100px !important; }
.ms-120 { margin-inline-start: 120px !important; }
.ms-150 { margin-inline-start: 150px !important; }
.ms-200 { margin-inline-start: 200px !important; }
.me-10 { margin-inline-end: 10px !important; }
.me-15 { margin-inline-end: 15px !important; }
.me-20 { margin-inline-end: 20px !important; }
.me-30 { margin-inline-end: 30px !important; }
.me-40 { margin-inline-end: 40px !important; }
.me-50 { margin-inline-end: 50px !important; }
.me-60 { margin-inline-end: 60px !important; }
.me-70 { margin-inline-end: 70px !important; }
.me-80 { margin-inline-end: 80px !important; }
.me-90 { margin-inline-end: 90px !important; }
.me-100 { margin-inline-end: 100px !important; }
.me-120 { margin-inline-end: 120px !important; }
.me-150 { margin-inline-end: 150px !important; }
.me-200 { margin-inline-end: 200px !important; }
.p-10 { padding: 10px !important; }
.p-15 { padding: 15px !important; }
.p-20 { padding: 20px !important; }
.p-30 { padding: 30px !important; }
.p-40 { padding: 40px !important; }
.p-50 { padding: 50px !important; }
.p-60 { padding: 60px !important; }
.p-70 { padding: 70px !important; }
.p-80 { padding: 80px !important; }
.p-90 { padding: 90px !important; }
.p-100 { padding: 100px !important; }
.p-120 { padding: 120px !important; }
.p-150 { padding: 150px !important; }
.p-200 { padding: 200px !important; }
.ps-10 { padding-inline-start: 10px !important; }
.ps-15 { padding-inline-start: 15px !important; }
.ps-20 { padding-inline-start: 20px !important; }
.ps-30 { padding-inline-start: 30px !important; }
.ps-40 { padding-inline-start: 40px !important; }
.ps-50 { padding-inline-start: 50px !important; }
.ps-60 { padding-inline-start: 60px !important; }
.ps-70 { padding-inline-start: 70px !important; }
.ps-80 { padding-inline-start: 80px !important; }
.ps-90 { padding-inline-start: 90px !important; }
.ps-100 { padding-inline-start: 100px !important; }
.ps-120 { padding-inline-start: 120px !important; }
.ps-150 { padding-inline-start: 150px !important; }
.ps-200 { padding-inline-start: 200px !important; }
.pe-10 { padding-inline-end: 10px !important; }
.pe-15 { padding-inline-end: 15px !important; }
.pe-20 { padding-inline-end: 20px !important; }
.pe-30 { padding-inline-end: 30px !important; }
.pe-40 { padding-inline-end: 40px !important; }
.pe-50 { padding-inline-end: 50px !important; }
.pe-60 { padding-inline-end: 60px !important; }
.pe-70 { padding-inline-end: 70px !important; }
.pe-80 { padding-inline-end: 80px !important; }
.pe-90 { padding-inline-end: 90px !important; }
.pe-100 { padding-inline-end: 100px !important; }
.pe-120 { padding-inline-end: 120px !important; }
.pe-150 { padding-inline-end: 150px !important; }
.pe-200 { padding-inline-end: 200px !important; }
.pt-10 { padding-top: 10px !important; }
.pt-15 { padding-top: 15px !important; }
.pt-20 { padding-top: 20px !important; }
.pt-30 { padding-top: 30px !important; }
.pt-40 { padding-top: 40px !important; }
.pt-50 { padding-top: 50px !important; }
.pt-60 { padding-top: 60px !important; }
.pt-70 { padding-top: 70px !important; }
.pt-80 { padding-top: 80px !important; }
.pt-90 { padding-top: 90px !important; }
.pt-100 { padding-top: 100px !important; }
.pt-120 { padding-top: 120px !important; }
.pt-150 { padding-top: 150px !important; }
.pt-200 { padding-top: 200px !important; }
.pb-10 { padding-bottom: 10px !important; }
.pb-15 { padding-bottom: 15px !important; }
.pb-20 { padding-bottom: 20px !important; }
.pb-30 { padding-bottom: 30px !important; }
.pb-40 { padding-bottom: 40px !important; }
.pb-50 { padding-bottom: 50px !important; }
.pb-60 { padding-bottom: 60px !important; }
.pb-70 { padding-bottom: 70px !important; }
.pb-80 { padding-bottom: 80px !important; }
.pb-90 { padding-bottom: 90px !important; }
.pb-100 { padding-bottom: 100px !important; }
.pb-120 { padding-bottom: 120px !important; }
.pb-150 { padding-bottom: 150px !important; }
.pb-200 { padding-bottom: 200px !important; }
.op-1 { opacity: 0.1; }
.op-2 { opacity: 0.2; }
.op-3 { opacity: 0.3; }
.op-4 { opacity: 0.4; }
.op-5 { opacity: 0.5; }
.op-6 { opacity: 0.6; }
.op-7 { opacity: 0.7; }
.op-8 { opacity: 0.8; }
.op-9 { opacity: 0.9; }
.op-10 { opacity: 1; }
.lh-1 { line-height: 1 !important; }
.lh-2 { line-height: 1.2 !important; }
.lh-3 { line-height: 1.4 !important; }
.lh-4 { line-height: 1.6 !important; }
.lh-5 { line-height: 1.8 !important; }
.lh-6 { line-height: 2 !important; }
.lh-7 { line-height: 2.5 !important; }
.lh-8 { line-height: 3 !important; }
.brd-light { border-color: rgba(255, 255, 255, 0.267) !important; }
.brd-gray { border-color: rgb(217, 217, 217) !important; }
.brd-gray2 { border-color: rgb(153, 153, 153) !important; }
.color-000 { color: rgb(0, 0, 0) !important; }
.color-111 { color: rgb(17, 17, 17) !important; }
.color-222 { color: rgb(34, 34, 34) !important; }
.color-333 {color: rgb(114 114 114) !important;}
.color-444 { color: rgb(68, 68, 68) !important; }
.color-555 { color: rgb(85, 85, 85) !important; }
.color-666 {color: rgb(164 164 164) !important;}
.color-777 { color: rgb(119, 119, 119) !important; }
.color-888 { color: rgb(136, 136, 136) !important; }
.color-999 { color: rgb(153, 153, 153) !important; }
.color-ccc { color: rgb(204, 204, 204) !important; }
.bg-000 { background-color: rgb(0, 0, 0) !important; }
.bg-111 { background-color: rgb(17, 17, 17) !important; }
.bg-222 { background-color: rgb(34, 34, 34) !important; }
.bg-333 { background-color: rgb(51, 51, 51) !important; }
.bg-444 { background-color: rgb(68, 68, 68) !important; }
.bg-555 { background-color: rgb(85, 85, 85) !important; }
.bg-666 { background-color: rgb(102, 102, 102) !important; }
.bg-777 { background-color: rgb(119, 119, 119) !important; }
.bg-888 { background-color: rgb(136, 136, 136) !important; }
.bg-999 { background-color: rgb(153, 153, 153) !important; }
.bg-ccc { background-color: rgb(204, 204, 204) !important; }
.bg-black { background-color: rgb(0, 0, 0) !important; }
.bg-dark1 { background-color: rgb(46, 46, 49) !important; }
.icon-3 { width: 3px; height: 3px; }
.icon-6 { width: 6px; height: 6px; }
.icon-8 { width: 8px; height: 8px; }
.icon-10 { width: 10px; height: 10px; }
.icon-15 { width: 15px; height: 15px; }
.icon-20 { width: 20px; height: 20px; }
.icon-25 { width: 25px; height: 25px; }
.icon-30 { width: 30px; height: 30px; }
.icon-35 { width: 35px; height: 35px; }
.icon-40 { width: 40px; height: 40px; }
.icon-45 { width: 45px; height: 45px; }
.icon-50 { width: 50px; height: 50px; }
.icon-55 { width: 55px; height: 55px; }
.icon-60 { width: 60px; height: 60px; }
.icon-65 { width: 65px; height: 65px; }
.icon-70 { width: 70px; height: 70px; }
.icon-75 { width: 75px; height: 75px; }
.icon-80 { width: 80px; height: 80px; }
.icon-85 { width: 85px; height: 85px; }
.icon-90 { width: 90px; height: 90px; }
.icon-95 { width: 95px; height: 95px; }
.icon-100 { width: 100px; height: 100px; }
.icon-125 { width: 125px; height: 125px; }
.icon-150 { width: 150px; height: 150px; }
.icon-175 { width: 175px; height: 175px; }
.icon-200 { width: 200px; height: 200px; }
.img-cover { width: 100%; height: 100%; object-fit: cover; display: inherit; }
.img-contain { width: 100%; height: 100%; object-fit: contain; display: inherit; }
.imgrotate-scale-hover { overflow: hidden; }
.imgrotate-scale-hover img { transition: all 2s ease 0s; }
.imgrotate-scale-hover:hover img { transform: rotate(-10deg) scale(1.2); }
.th-10 { height: 10px !important; }
.th-20 { height: 20px !important; }
.th-30 { height: 30px !important; }
.th-40 { height: 40px !important; }
.th-50 { height: 50px !important; }
.th-60 { height: 60px !important; }
.th-70 { height: 70px !important; }
.th-80 { height: 80px !important; }
.th-90 { height: 90px !important; }
.th-100 { height: 100px !important; }
.th-120 { height: 120px !important; }
.th-140 { height: 140px !important; }
.th-160 { height: 160px !important; }
.th-180 { height: 180px !important; }
.th-200 { height: 200px !important; }
.th-230 { height: 230px !important; }
.th-250 { height: 250px !important; }
.th-280 { height: 280px !important; }
.th-300 { height: 300px !important; }
.th-330 { height: 330px !important; }
.th-350 { height: 350px !important; }
.th-380 { height: 380px !important; }
.th-400 { height: 400px !important; }
.th-425 { height: 425px !important; }
.th-450 { height: 450px !important; }
.th-475 { height: 475px !important; }
.th-500 { height: 500px !important; }
.th-525 { height: 525px !important; }
.th-550 { height: 550px !important; }
.th-575 { height: 575px !important; }
.th-600 { height: 600px !important; }
.th-650 { height: 650px !important; }
.th-700 { height: 700px !important; }
.th-750 { height: 750px !important; }
.th-800 { height: 800px !important; }
.th-900 { height: 900px !important; }
.th-1000 { height: 1000px !important; }
.ltspc-0 { letter-spacing: 0px !important; }
.ltspc-1 { letter-spacing: 1px !important; }
.ltspc-2 { letter-spacing: 2px !important; }
.ltspc-3 { letter-spacing: 3px !important; }
.ltspc-4 { letter-spacing: 4px !important; }
.ltspc-5 { letter-spacing: 5px !important; }
.ltspc-8 { letter-spacing: 8px !important; }
.ltspc-10 { letter-spacing: 10px !important; }
.ltspc-12 { letter-spacing: 12px !important; }
.ltspc-15 { letter-spacing: 15px !important; }
.ltspc-17 { letter-spacing: 17px !important; }
.ltspc-20 { letter-spacing: 20px !important; }
.ltspc--1 { letter-spacing: -1px !important; }
.ltspc--2 { letter-spacing: -2px !important; }
.ltspc--3 { letter-spacing: -3px !important; }
.fsz-10 { font-size: 10px !important; }
.fsz-11 { font-size: 11px !important; }
.fsz-12 { font-size: 12px !important; }
.fsz-13 { font-size: 13px !important; }
.fsz-14 { font-size: 14px !important; }
.fsz-15 { font-size: 15px !important; }
.fsz-16 { font-size: 16px !important; }
.fsz-17 { font-size: 17px !important; }
.fsz-18 { font-size: 18px !important; }
.fsz-19 { font-size: 19px !important; }
.fsz-20 { font-size: 20px !important; }
.fsz-21 { font-size: 21px !important; }
.fsz-22 { font-size: 22px !important; }
.fsz-23 { font-size: 23px !important; }
.fsz-24 { font-size: 24px !important; }
.fsz-25 { font-size: 25px !important; }
.fsz-26 { font-size: 26px !important; }
.fsz-27 { font-size: 27px !important; }
.fsz-28 { font-size: 28px !important; }
.fsz-29 { font-size: 29px !important; }
.fsz-30 { font-size: 30px !important; }
.fsz-35 { font-size: 35px !important; }
.fsz-40 { font-size: 40px !important; }
.fsz-45 { font-size: 45px !important; }
.fsz-50 { font-size: 50px !important; }
.fsz-55 { font-size: 55px !important; }
.fsz-60 { font-size: 60px !important; }
.fsz-65 { font-size: 65px !important; }
.fsz-70 { font-size: 70px !important; }
.fsz-75 { font-size: 75px !important; }
.fsz-80 { font-size: 80px !important; }
.fsz-85 { font-size: 85px !important; }
.fsz-90 { font-size: 90px !important; }
.fsz-95 { font-size: 95px !important; }
.fsz-100 { font-size: 100px !important; }
.fw-100 { font-weight: 100 !important; }
.fw-200 { font-weight: 200 !important; }
.fw-300 { font-weight: 300 !important; }
.fw-400 { font-weight: 400 !important; }
.fw-500 { font-weight: 500 !important; }
.fw-600 { font-weight: 600 !important; }
.fw-700 { font-weight: 700 !important; }
.fw-800 { font-weight: 800 !important; }
.fw-900 { font-weight: 900 !important; }
.znd1 { z-index: 1; }
.znd2 { z-index: 2; }
.znd3 { z-index: 3; }
.znd4 { z-index: 4; }
.znd5 { z-index: 5; }
.znd6 { z-index: 6; }
.znd7 { z-index: 7; }
.znd8 { z-index: 8; }
.znd9 { z-index: 9; }
.znd10 { z-index: 10; }
.znd25 { z-index: 25; }
.znd50 { z-index: 50; }
.znd75 { z-index: 75; }
.znd100 { z-index: 100; }
.znd200 { z-index: 200; }
.tr-delay1 { transition-delay: 0.2s; }
.tr-delay2 { transition-delay: 0.4s; }
.tr-delay3 { transition-delay: 0.6s; }
.tr-delay4 { transition-delay: 0.8s; }
.tr-delay5 { transition-delay: 1s; }
.hover-underline:hover { text-decoration: underline !important; }
.hover-white:hover { color: rgb(255, 255, 255); }
.hover-white:hover * { color: rgb(255, 255, 255); }
.butn {padding: 15px 40px;transition: all 0.2s ease 0s;position: relative;color: #000;}
.cont .butn {padding: 15px 40px;transition: all 0.2s ease 0s;position: relative;color: #fff;}

.butn.md-butn { padding: 10px 25px; }
.butn:hover { box-shadow: rgba(0, 0, 0, 0.267) 7px 10px 15px; transform: translateY(-2px); }
:root { --color-blue1: #4b3ec4; --color-orange1: #e3e3e3; --color-yellow1: e3e3e3; --color-green1: #689210; --color-brown1: #E5C696; --color-brown2: #7D764D; --color-light1: #eff3f6; }
.color-blue1 { color: var(--color-blue1) !important; }
.bg-blue1 { background: var(--color-blue1) !important; }
.border-blue1 { border-color: var(--color-blue1) !important; }
.hover-blue1:hover { color: var(--color-blue1) !important; }
.hover-bg-blue1:hover { background: var(--color-blue1) !important; border-color: var(--color-blue1) !important; color: rgb(255, 255, 255) !important; }
.hover-bg-blue1:hover * { color: rgb(255, 255, 255) !important; }
.color-orange1 { color: var(--color-orange1) !important; }
.bg-orange1 {background: var(--color-orange1) !important;color: #000;}
.bg-orange1 span {color: #000}
.border-orange1 { border-color: var(--color-orange1) !important; }
.hover-orange1:hover { color: var(--color-orange1) !important; }
.hover-bg-orange1:hover { border-color: var(--color-orange1) !important; background: var(--color-orange1) !important; color: rgb(255, 255, 255) !important; }
.hover-bg-orange1:hover * { color: rgb(255, 255, 255) !important; }
.color-yellow1 { color: var(--color-yellow1) !important; }
.bg-yellow1 { background: var(--color-yellow1) !important; }
.border-yellow1 { border-color: var(--color-yellow1) !important; }
.hover-yellow1:hover { color: var(--color-yellow1) !important; }
.hover-bg-yellow1:hover { color: rgb(255, 255, 255); background: var(--color-yellow1) !important; border-color: var(--color-yellow1) !important; }
.hover-bg-yellow1:hover * { color: rgb(255, 255, 255) !important; }
.color-green1 { color: var(--color-green1) !important; }
.bg-green1 { background: var(--color-green1) !important; }
.border-green1 { border-color: var(--color-green1) !important; }
.hover-green1:hover { color: var(--color-green1) !important; }
.hover-bg-green1:hover { color: rgb(255, 255, 255); background: var(--color-green1) !important; border-color: var(--color-green1) !important; }
.hover-bg-green1:hover * { color: rgb(255, 255, 255) !important; }
.color-brown1 { color: var(--color-brown1) !important; }
.bg-brown1 { background: var(--color-brown1) !important; }
.border-brown1 { border-color: var(--color-brown1) !important; }
.hover-brown1:hover { color: var(--color-brown1) !important; }
.hover-bg-brown1:hover { color: rgb(255, 255, 255); background: var(--color-brown1) !important; border-color: var(--color-brown1) !important; }
.hover-bg-brown1:hover * { color: rgb(255, 255, 255) !important; }
.color-brown2 { color: var(--color-brown2) !important; }
.bg-brown2 { background: var(--color-brown2) !important; }
.border-brown2 { border-color: var(--color-brown2) !important; }
.hover-brown2:hover { color: var(--color-brown2) !important; }
.hover-bg-brown2:hover { color: rgb(255, 255, 255); background: var(--color-brown2) !important; border-color: var(--color-brown2) !important; }
.hover-bg-brown2:hover * { color: rgb(255, 255, 255) !important; }
.hover-white:hover { color: rgb(255, 255, 255) !important; }
.hover-bg-white:hover { background-color: rgb(255, 255, 255) !important; color: rgb(0, 0, 0) !important; }
.hover-bg-white:hover * { color: rgb(0, 0, 0); }
.hover-black:hover { color: rgb(0, 0, 0) !important; }
.hover-bg-black:hover { background-color: rgb(0, 0, 0) !important; border-color: rgb(0, 0, 0) !important; color: rgb(255, 255, 255) !important; }
.hover-bg-black:hover * { color: rgb(255, 255, 255); }
  